import { Injectable, EventEmitter } from '@angular/core';
import { constants } from 'src/config/constants';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchByBrandServiceSeed } from './search-by-brand.service.seed';

@Injectable({
  providedIn: 'root',
})
export class SearchByBrandService {
  private BASE_URL = constants.apiHost + '/brand';
  private fakeAPIResponse: boolean = false;

  searchFocusEvent: EventEmitter<boolean>;

  constructor(private http: HttpClient) {
    this.searchFocusEvent = new EventEmitter();
  }

  setFocus(isFocused?: boolean) {
    this.searchFocusEvent.emit(isFocused);
  }

  autosuggest(q: string) {
    if (environment.fakeAPIResponse || this.fakeAPIResponse) {
      return SearchByBrandServiceSeed.autosuggest(q);
    }
    return this.http.get(this.BASE_URL + '/search', { params: { q: q } });
  }
}
