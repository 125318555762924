import { Component } from '@angular/core';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-swiper-testt',
  templateUrl: './swiper-testt.component.html',
  styleUrl: './swiper-testt.component.scss'
})
export class SwiperTesttComponent {
  swiperConfig: SwiperOptions = {
    slidesPerView: 2,
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: true,
    autoplay: { delay: 1000 },
    spaceBetween: 30
  };
}
