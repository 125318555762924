import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-preview-golive',
  templateUrl: './preview-golive.component.html',
  styleUrls: ['./preview-golive.component.scss'],
})
export class PreviewGoliveComponent implements OnInit {
  @ViewChild('modalPreviewGoLive', { static: true }) modalPreviewGoLive: TemplateRef<any>;

  isLoading: boolean = false;
  isPreviewGoLiveEnabled: boolean = environment.isPreviewGoLiveEnabled;

  loginForm: UntypedFormGroup;
  step: number = 1;
  goLiveResponseStatus: number = -1;
  goLiveResponseMessage: string = 'Sorry, unable to schedule the promotion of For-You preview to production. Please try again later.';

  bsModalRef: BsModalRef;

  get loginFormControls() {
    return this.loginForm.controls;
  }

  get goLiveStatus(): 'default' | 'success' | 'warning' | 'error' {
    if (this.goLiveResponseStatus >= 200 && this.goLiveResponseStatus < 300) {
      return 'success';
    } else if (this.goLiveResponseStatus >= 400 && this.goLiveResponseStatus < 500) {
      return 'warning';
    } else if (this.goLiveResponseStatus === 0 || this.goLiveResponseStatus >= 500) {
      return 'error';
    }
    return 'default';
  }

  constructor(private modalService: BsModalService, private formBuilder: UntypedFormBuilder, private loginService: LoginService) {}

  ngOnInit() {}

  onPreviewGoLiveAction() {
    // Initialize form
    this.loginForm = this.formBuilder.group({
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(environment.emailIdRegExp), Validators.maxLength(63)]),
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern(/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*~`=|<>?:.,}{;/._()+-])[a-zA-Z0-9!@#$%^&*~`=|<>?:.,}{;/._()+-]{8,20}$/),
      ]),
    });

    // Reset the form
    this.step = 1;
    this.goLiveResponseStatus = -1;
    // Set is Loading to false
    this.isLoading = false;
    // Show modal
    this.bsModalRef = this.modalService.show(this.modalPreviewGoLive, {
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered preview-go-live-modal',
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onLoginFormSubmit() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.goLiveResponseStatus = -1;
      this.loginService.login(this.loginForm.value).subscribe(
        (response: any) => {
          if (response && response.success) {
            this.goLiveResponseStatus = 200;
            this.goLiveResponseMessage =
              response.message || 'Promotion of For-You preview to production has been scheduled. You will get a notification on Slack once it completes';
            this.step = 3;
            this.isLoading = false;
          }
        },
        (err) => {
          if (err.status >= 500) {
            console.error('Error while requesting preview changes to go live', err);
          }
          this.goLiveResponseStatus = err.status;
          this.goLiveResponseMessage =
            err.error?.message ||
            (err.status === 0
              ? 'Looks like you are offline. Please check your internet connection and retry'
              : 'Sorry, unable to take For-You preview changes to production at this time. Please try again later');
          this.step = 3;
          this.isLoading = false;
        }
      );
    }
  }
}
