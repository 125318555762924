import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';

import { DisclaimerNoticeComponent } from './disclaimer-notice.component';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [DisclaimerNoticeComponent],
  imports: [CoreModule, CommonModule, ModalModule.forRoot()],
  exports: [DisclaimerNoticeComponent],
})
export class DisclaimerNoticeModule {}
