import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { constants } from 'src/config/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SpendAnalyzerService {
  private BASE_URL = constants.apiHost + '/spend-analyzer';
  fakeAPIResponse: boolean = false;
  private history: string[] = [];

  constructor(private http: HttpClient, private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  getYearMonth() {
    return this.http.get(this.BASE_URL + '/analyzer-spends-month-year');
  }

  getAnalyzerSpends(yearMonth): Observable<any> {
    const params: any = {
      yearMonth: yearMonth,
    };
    return this.http.get(this.BASE_URL + '/analyzer-spends', { params: params });
  }
}
