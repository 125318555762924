import { Routes } from '@angular/router';
import { NavigationService } from 'src/app/core/navigation/navigation.service';

export const TABBED_PAGE_LAYOUT_ROUTES: Routes = [
  {
    path: '404',
    loadChildren: () => import('../../pages/error-not-found-page/error-not-found-page.module').then((m) => m.ErrorNotFoundPageModule),
  },
  {
    path: 'o',
    loadChildren: () => import('../../pages/error-not-found-page/error-not-found-page.module').then((m) => m.ErrorNotFoundPageModule),
    canActivate: [NavigationService],
  },
  {
    path: '',
    loadChildren: () => import('../../pages/content-list-page/content-list-page.module').then((m) => m.ContentListPageModule),
  },
];
