import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AnalyticsService } from '../services/analytics.service';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private _analyticsService: AnalyticsService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    const url = new URL(window.location.href);
    const beaconingKey = url.searchParams.get('t');
    if (this._analyticsService.user) {
      // See if the beaconingKey is set in ?t= query param
      request = request.clone({ headers: request.headers.set(environment.identityHeaderName, this._analyticsService.user) });
      request = request.clone({
        params: (request.params ? request.params : new HttpParams()).set('cacheid', this._analyticsService.user),
      });
    } else if (beaconingKey) {
      // See if the beaconingKey is set in ?t= query param
      request = request.clone({ headers: request.headers.set(environment.identityHeaderName, beaconingKey) });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const userId = event.headers.get(environment.identityHeaderName);
          const sessionId = event.headers.get(environment.sessionIdHeaderName);
          if (sessionId) {
            this._analyticsService.sessionId = sessionId;
            this._analyticsService.setAnonymousId(sessionId);
          }
          if (this._analyticsService.user != userId) {
            this._analyticsService.user = userId;
            this._analyticsService.identify(userId);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // Check if we got a 301 / 302 response from API and redirect accordingly
        if (error && (error.status === 301 || error.status === 302) && error.error && error.error.redirectUrl) {
          this.router.navigateByUrl(error.error.redirectUrl, { skipLocationChange: true });
        } else {
          // Log the error
          console.error('API Error', {
            req: {
              url: request.method + ' ' + request.urlWithParams,
              // Don't log body if HTTP error is 413: Request entity too large
              body: !(error.error instanceof ErrorEvent) && error.status !== 413 ? request.body : 'Truncated due to body being too large',
              headers: request.headers,
            },
            error: error,
          });
        }
        return throwError(error);
      })
    );
  }
}
