<section class="menu-wrapper" *ngIf="isReady" [ngStyle]="{ 'pointer-events': isOpen ? 'auto' : 'none' }">
  <div
    class="menu-container animate__animated"
    [ngClass]="isOpen ? 'animate__slideInLeft d-flex flex-wrap' : hasOpened ? 'animate__slideOutLeft d-flex flex-wrap' : 'd-none'"
  >
    <div class="col-12 list-group list-group-flush" *ngIf="!isLoading && !isError">
      <button
        class="list-group-item menu-go-back-button cursor-pointer"
        (click)="goBack()"
        trackMouseEvents
        [trackData]="{
          property: 'menu-go-back',
          realEstateId: 'menu-go-back-button',
          title: 'Go back',
          template: 'menu'
        }"
        aria-label="Go back"
      >
        <span class="text-white list-group-item-action" id="menu-go-back-button"> <i class="icon icon-arrow-left"></i> Go back </span>
      </button>
      <a
        class="list-group-item menu-item cursor-pointer"
        *ngFor="let menu of menuItems; let i = index"
        id="menu-item-{{ i + 1 }}"
        [routerLink]="menu.ctaURL"
        routerLinkActive="active"
        trackMouseEvents
        [trackData]="{
          property: 'menu-item',
          realEstateId: 'menu-item-' + i + 1,
          ctaURL: menu.ctaURL,
          title: menu.label,
          template: 'menu'
        }"
        [attr.data-collection-title]="menu.collectionTitle"
        [attr.data-menu-label]="menu.label"
        [attr.aria-label]="menu.label"
      >
        <span class="list-group-item-action">
          {{ menu.label }}
        </span>
      </a>
    </div>
    <div class="loader-container p-2" *ngIf="isLoading && !isError">
      <ul class="list-group list-group-flush">
        <li class="list-group-item list-group-item-action position-relative my-1" *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
          <app-lazy-load-img></app-lazy-load-img>
        </li>
      </ul>
    </div>
    <div class="error-container px-2 py-4 w-100" *ngIf="!isLoading && isError">
      <div class="error d-inline-block text-center w-100">
        <app-error-state
          idContext="menu"
          [retryError]="retryError"
          (retryTask)="retry()"
          [trackData]="{
            property: 'retry',
            realEstateId: 'menu-retry-button',
            title: 'Retry',
            template: 'menu'
          }"
        ></app-error-state>
      </div>
    </div>
    <div class="col-12 align-self-end app-version">
      About App <span style="float: right"> v{{ appVersion }} </span>
    </div>
  </div>
  <div
    *ngIf="isOpen"
    class="menu-backdrop animate__animated animate__fadeIn animate__delay-1s animate__faster cursor-pointer"
    id="menu-back-drop"
    (click)="collapseMenu()"
    trackMouseEvents
    [trackData]="{
      property: 'menu-backdrop',
      realEstateId: 'menu-back-drop',
      template: 'menu'
    }"
  ></div>
</section>
