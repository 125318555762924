import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationDirective, NavigationWithHrefDirective } from './navigation.directive';

@NgModule({
  declarations: [NavigationDirective, NavigationWithHrefDirective],
  imports: [CommonModule],
  exports: [NavigationDirective, NavigationWithHrefDirective],
})
export class NavigationModule {}
