import { Injectable } from '@angular/core';
import { constants } from 'src/config/constants';
import { HttpClient } from '@angular/common/http';
import { FooterServiceSeed } from './footer.service.seed';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private BASE_URL = constants.apiHost;
  private fakeAPIResponse: boolean = false;

  constructor(private http: HttpClient, private _seed: FooterServiceSeed) {}

  getFooterContent() {
    if (this.fakeAPIResponse || environment.fakeAPIResponse) {
      return this._seed.getFooterContent();
    }
    return this.http.get(this.BASE_URL + '/footer');
  }
}
