import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  private _isUpdateAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly isUpdateAvailable: Observable<boolean> = this._isUpdateAvailable.asObservable();

  constructor(private readonly updates: SwUpdate) {
    this.checkForUpdates();
  }

  private checkForUpdates() {
    // Check if the service worker is enabled
    if (this.updates.isEnabled) {
      // Subscribe to the updates observable
      this.updates.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_READY') {
          this._showAppUpdateAlert(); // Show alert when an update is available
        }
      });
    }
  }

  doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  private _showAppUpdateAlert() {
    this._isUpdateAvailable.next(true); // Notify that an update is available
  }
}


