import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { constants } from 'src/config/constants';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private BASE_URL = constants.apiHost;
  private _isSettled: boolean = false;
  constructor(private http: HttpClient) {}

  getSessionExpiry(timer: number) {
    setTimeout(async () => {
      this._isSettled = false;
      await this.getConfig();
    }, timer * 1000);
  }

  getConfig(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this._isSettled) {
        return resolve(true);
      }
      this.http.get(this.BASE_URL + '/config', { observe: 'response' }).subscribe(
        (resp: any) => {
          if (resp && resp.body && resp.body.data && resp.body.data.env) {
            Object.assign(environment, resp.body.data.env);
            this._isSettled = true;
            this.getSessionExpiry(resp.headers.get(environment.sessionIdExpiryName));
            return resolve(true);
          }

          return reject(new Error('Malformed Config Response'));
        },
        (err) => {
          console.error('Error getting config', err);
          return reject(err);
        }
      );
    });
  }
}
