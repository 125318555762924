// auth.guard.ts

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuardRoute  {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (environment.spendAnalyzerEnabled) {
      return true;
    } else {
      // Redirect to login page if not authenticated
      this.router.navigate(['/home']);
      return false;
    }
  }
}
