import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { constants } from 'src/config/constants';
import { environment } from 'src/environments/environment';
import { MenuServiceSeed } from './menu.service.seed';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private BASE_URL = constants.apiHost + '/menu';
  fakeAPIResponse: boolean = false;

  private history: string[] = [];

  constructor(private http: HttpClient, private _seed: MenuServiceSeed, private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  getMenu(): Observable<any> {
    if (this.fakeAPIResponse || environment.fakeAPIResponse) {
      return this._seed.getMenu();
    }
    return this.http.get(this.BASE_URL);
  }
}
