import { Component, OnInit } from '@angular/core';
import { PageTitleService } from './services/page-title.service';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { HostListener } from '@angular/core';

import { filter, map, mergeMap } from 'rxjs/operators';
import { AppUpdateService } from './services/app-update.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isAppUpdateAvailable: boolean = false;

  // lastTouchTime is used for ignoring emulated mousemove events
  lastTouchTime: number = 0;
  hasHover: boolean = false;

  isInitializing: boolean = false;
  showSpendIndicatorIcon: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private pageTitleService: PageTitleService, private _appUpdateService: AppUpdateService) {
    this._appUpdateService.isUpdateAvailable.subscribe((isUpdateAvailable: boolean) => {
      this.isAppUpdateAvailable = isUpdateAvailable;
    });
  }

  @HostListener('document:touchstart', ['$event'])
  onTouchStart() {
    this._updateLastTouchTime();
  }

  @HostListener('document:touchstart', ['$event'])
  onTouchStartAgain() {
    this._disableHover();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove() {
    this._enableHover();
  }

  ngOnInit() {
    this._handlePageScrolls();
    // this._init();
  }

  retry() {
    this._init();
  }

  doAppUpdate() {
    this.isInitializing = true;
    this._appUpdateService.doAppUpdate();
  }

  private _handlePageScrolls() {
    const scrollLevels: { [navigationId: number]: number } = {};
    let lastId = 0;
    let restoredId: number;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        scrollLevels[lastId] = window.scrollY;
        lastId = event.id;
        restoredId = event.restoredState ? event.restoredState.navigationId : undefined;
      }

      if (event instanceof NavigationEnd) {
        if(environment.showSpendIndicatorIcon){
          (<HTMLElement>document.querySelector('.spend-analyzer-button')).style.display = 'block';
        }
        this.showSpendIndicatorIcon = environment.spendAnalyzerEnabled && environment.showSpendIndicatorIcon && event.url.indexOf('/spend-analyzer') === -1 ;
        setTimeout(() => {
          if (restoredId) {
            window.scrollTo(0, scrollLevels[restoredId] || 0);
          } else {
            window.scrollTo(0, 0);
          }
        }, 100);
      }
    });
  }

  private _enableHover() {
    if (new Date().getMilliseconds() - this.lastTouchTime < 500) {
      return;
    }
    this.hasHover = true;
  }

  private _disableHover() {
    this.hasHover = false;
  }

  private _updateLastTouchTime() {
    this.lastTouchTime = new Date().getMilliseconds();
  }

  private _init() {
    // Page Title
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((data: any) => {
        if (data && data.title) {
          this.pageTitleService.setBrowserTitle(data.title);
        }
        if (data && data.pageTitle) {
          this.pageTitleService.setPageTitle(data.pageTitle);
        }
      });
  }
}
