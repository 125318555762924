import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FooterServiceSeed {
  getFooterContent() {
    return of({
      data: {
        result: [
          {
            label: 'Explore by categories',
            images: [],
            icons: [],
            lastIcon: 'icon-angle-right',
            ctaURL: '/collection/all-categories/31',
            orderClass: 'order-sm-1 order-xl-1',
          },
          {
            label: 'Explore by brands',
            images: [],
            icons: [],
            lastIcon: 'icon-angle-right',
            ctaURL: '/collection/all-brands/32',
            orderClass: 'order-sm-2 order-xl-4',
          },
          {
            label: 'Offers for you',
            images: [],
            lastIcon: 'icon-angle-right',
            ctaURL: '/collection/offers-for-you/115',
            orderClass: 'order-sm-3 order-xl-7',
          },
          {
            label: 'About ' + environment.productName,
            images: [],
            icons: [],
            lastIcon: 'icon-angle-right',
            ctaURL: '/about',
            smallScreenOrder: 'order-sm-4',
            largeScreenOrder: 'order-md-5',
            orderClass: 'order-sm-4 order-xl-2',
          },
          {
            label: 'Terms of use',
            images: [],
            icons: [],
            lastIcon: 'icon-angle-right',
            ctaURL: '/tnc',
            orderClass: 'order-sm-5 order-xl-5',
          },
          {
            label: 'Privacy policy',
            images: [],
            icons: [],
            lastIcon: 'icon-angle-right',
            ctaURL: '/privacy-policy',
            orderClass: 'order-sm-6 order-xl-8',
          },
          {
            label: 'Contact us',
            images: [],
            icons: [],
            lastIcon: 'icon-angle-right',
            ctaURL: '/contact',
            orderClass: 'order-sm-7 order-xl-3',
          },
          {
            label: 'IndusMobile app',
            images: [
              { path: './assets/images/generic/app-store.png', ctaURL: 'https://apps.apple.com/in/app/indusmobile/id499499672' },
              { path: './assets/images/generic/google-play.png', ctaURL: 'https://play.google.com/store/apps/details?id=com.fss.indus&hl=en' },
            ],
            icons: [],
            lastIcon: '',
            ctaURL: null,
            orderClass: 'order-sm-7 order-xl-6',
          },
          {
            label: 'Follow IndusInd Bank',
            images: [],
            icons: [
              { name: 'icon-facebook', ctaURL: 'https://www.facebook.com/OfficialIndusIndBankPage' },
              { name: 'icon-twitter', ctaURL: 'https://twitter.com/myindusindbank' },
              { name: 'icon-instagram', ctaURL: 'https://www.instagram.com/indusind_bank' },
            ],
            lastIcon: '',
            ctaURL: null,
            orderClass: 'order-sm-8 order-xl-9',
          },
        ],
      },
    });
  }
}
