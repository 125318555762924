import { Injectable } from '@angular/core';
import { constants } from 'src/config/constants';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ContentServiceSeed } from './content.service.seed';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private BASE_URL = constants.apiHost;
  private fakeAPIResponse: boolean = false;

  constructor(private http: HttpClient) {}

  getPageContent(page: number, pageType: string): Observable<any> {
    if (environment.fakeAPIResponse || this.fakeAPIResponse) {
      return ContentServiceSeed.getPageContent(page, pageType);
    }
    const params: any = {
      page: page,
    };
    Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);
    return this.http.get(this.BASE_URL + '/page/' + pageType, {
      params: params,
    });
  }

  getContentDetails(contentType: string, contentId: string, page?: number): Observable<any> {
    if (environment.fakeAPIResponse || this.fakeAPIResponse) {
      return ContentServiceSeed.getContentDetails(contentType, contentId, page);
    }
    const params: any = {
      page: page || '',
    };
    return this.http.get(this.BASE_URL + '/details/' + contentType + '/' + contentId, {
      params: params,
    });
  }

  getRelatedContentDetails(filters:any, page?: number): Observable<any> {
    if (environment.fakeAPIResponse || this.fakeAPIResponse) {
      return ContentServiceSeed.getRelatedContentDetails( page);
    }
    const params: any = {
      page: page || '',
      ...filters
    };
    
    return this.http.get(this.BASE_URL + '/details', {
      params: params,
    });
  }

  
}
