import version  from '../../package.json';

export const environment: any = {
  production: true,
  fakeAPIResponse: false,
  offerEndDateLabel: 'Valid Until:',
  productFeatureEndDateLabel: 'Valid Until:',
  articleEndDateLabel: 'Valid Until:',
  appVersion: version,
  /**
   * The below are properties are overridden in app.component
   */
  identityHeaderName: 'x-identity',
  sessionIdHeaderName: 'x-session-id',
  sessionIdExpiryName: 'x-session-expiry',
  productName: 'For You',
  logo: '/assets/images/logos/logo.png',
  bankName: 'Goals101 Bank',
  bankNameWithVariant: 'Goals101 Bank Card',
  showBankConstruct: true,
  searchInputPlaceholderText: 'Brand search',
  expiredOfferCtaText: 'Explore more offers',
  expiredOfferDescription: 'This offer is expired!',
  expiredOfferBadgeText: 'Offer Expired!',
  emailIdRegExp: new RegExp('^[a-z0-9._%+][a-z0-9._%+-]+@[a-z0-9.-]+[.]{1}[a-z]{2,63}$', 'i'),
  skipDetailPageIfSingleContent: {
    collection: false,
    category: false,
    brand: true,
    tag: false,
  },
  hideContentType: {
    collection: true,
    category: false,
    brand: true,
    article: true,
  },
  hideDetailPageLogo: {
    collection: true,
    category: true,
    brand: true,
    article: true,
  },
  analytics: {
    rudderstack: {
      dataPlaneUrl: '',
      writeKey: '',
    },
  },
  showRibbonLegend: true,
  ribbonLegend: {
    icon: 'icon-bolt',
    text: 'Get additional 200 reward points on your Bank Card',
  },
  featureFlags: {
    showMenu: true,
    showSearchBar: true,
  },
  disclaimer: {
    position: null,
    text: 'For You is a platform hosted by Goals101 Bank\'s authorized partner Goals101 Data Solutions Pvt. Ltd. ("Goals101") only for display of offers extended by merchants to eligible Bank Card customers. No personal information, as provided on the website by Bank customers will be shared with Goals101 or other partners for the purpose of this program. We would like to inform you that the platform uses analytics to make your experience with us more delightful and personalized.<br /><br />As you choose to proceed, any purchase of a product/service shall only be through Bank Card. The products/services offered by Merchants may also be available at other stores/online platforms. Customer discretion is advised in this regard and proceeding hereunder indicates consent and approval to these terms.',
  },
  spendAnalyzer: {
    homePage: {
      title: 'New Feature Alert!',
      description: 'Explore Spend Analyzer for a clear view of your spending habits',
      text: 'Learn More',
    },
    page: {
      title: 'Spend Analysis',
      description: 'Explore Spend analyzer for a clear view of your spending habits',
      showRelatedOffers: true,
    },
  },
  bankCopyrightText: '',
  schema: {
    identify: {
      anonymousId: { type: 'string' },
      userId: { type: 'string' },
      timestamp: { type: 'string' },
      context: {
        locale: { type: 'string' },
        userAgent: { type: 'string' },
        screen: {
          type: 'object',
          properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
          required: ['devicePixelRatio', 'width', 'height'],
        },
        page: {
          type: 'object',
          properties: {
            path: { type: 'string' },
            referrer: { type: 'string' },
            search: { type: 'string' },
            title: { type: 'string' },
            url: { type: 'string' },
          },
          required: ['path', 'referrer', 'search', 'title', 'url'],
        },
        library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
      },
    },
    page: {
      home: {
        type: 'object',
        properties: {
          anonymousId: { type: 'string' },
          userId: { type: 'string' },
          timestamp: { type: 'string' },
          name: { type: 'string' },
          properties: { type: 'object', properties: { pageTitle: { type: 'string' }, category: { type: 'string' } }, required: ['pageTitle', 'category'] },
          context: {
            type: 'object',
            properties: {
              locale: { type: 'string' },
              userAgent: { type: 'string' },
              screen: {
                type: 'object',
                properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                required: ['devicePixelRatio', 'width', 'height'],
              },
              page: {
                type: 'object',
                properties: {
                  path: { type: 'string' },
                  referrer: { type: 'string' },
                  search: { type: 'string' },
                  title: { type: 'string' },
                  url: { type: 'string' },
                },
                required: ['path', 'referrer', 'search', 'title', 'url'],
              },
              library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
            },
            required: ['locale', 'userAgent', 'screen', 'page', 'library'],
          },
        },
        required: ['anonymousId', 'userId', 'timestamp', 'name', 'properties', 'context'],
      },
      offer: {
        type: 'object',
        properties: {
          anonymousId: { type: 'string' },
          userId: { type: 'string' },
          timestamp: { type: 'string' },
          name: { type: 'string' },
          properties: {
            type: 'object',
            properties: {
              offer: {
                type: 'object',
                properties: {
                  id: { type: 'number' },
                  offerText: { type: 'string' },
                  ctaURL: { type: 'string' },
                  ctaText: { type: 'string' },
                  brandId: { type: 'number' },
                  brandName: { type: 'string' },
                },
                required: ['id', 'offerText', 'ctaURL', 'ctaText', 'brandId', 'brandName'],
              },
              category: { type: 'string' },
            },
            required: ['offer', 'category'],
          },
          context: {
            type: 'object',
            properties: {
              locale: { type: 'string' },
              userAgent: { type: 'string' },
              screen: {
                type: 'object',
                properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                required: ['devicePixelRatio', 'width', 'height'],
              },
              page: {
                type: 'object',
                properties: {
                  path: { type: 'string' },
                  referrer: { type: 'string' },
                  search: { type: 'string' },
                  title: { type: 'string' },
                  url: { type: 'string' },
                },
                required: ['path', 'referrer', 'search', 'title', 'url'],
              },
              library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
            },
            required: ['locale', 'userAgent', 'screen', 'page', 'library'],
          },
        },
        required: ['anonymousId', 'userId', 'timestamp', 'name', 'properties', 'context'],
      },
      collection: {
        type: 'object',
        properties: {
          anonymousId: { type: 'string' },
          userId: { type: 'string' },
          timestamp: { type: 'string' },
          name: { type: 'string' },
          properties: {
            type: 'object',
            properties: {
              collection: {
                type: 'object',
                properties: { id: { type: 'number' }, title: { type: 'string' }, ctaText: { type: 'string' } },
                required: ['id', 'title', 'ctaText'],
              },
              category: { type: 'string' },
            },
            required: ['collection', 'category'],
          },
          context: {
            type: 'object',
            properties: {
              locale: { type: 'string' },
              userAgent: { type: 'string' },
              screen: {
                type: 'object',
                properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                required: ['devicePixelRatio', 'width', 'height'],
              },
              page: {
                type: 'object',
                properties: {
                  path: { type: 'string' },
                  referrer: { type: 'string' },
                  search: { type: 'string' },
                  title: { type: 'string' },
                  url: { type: 'string' },
                },
                required: ['path', 'referrer', 'search', 'title', 'url'],
              },
              library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
            },
            required: ['locale', 'userAgent', 'screen', 'page', 'library'],
          },
        },
        required: ['anonymousId', 'userId', 'timestamp', 'name', 'properties', 'context'],
      },
      'content-category': {
        type: 'object',
        properties: {
          anonymousId: { type: 'string' },
          userId: { type: 'string' },
          timestamp: { type: 'string' },
          name: { type: 'string' },
          properties: {
            type: 'object',
            properties: { contentCategory: { type: 'object', properties: { id: { type: 'number' } }, required: ['id'] }, category: { type: 'string' } },
            required: ['contentCategory', 'category'],
          },
          context: {
            type: 'object',
            properties: {
              locale: { type: 'string' },
              userAgent: { type: 'string' },
              screen: {
                type: 'object',
                properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                required: ['devicePixelRatio', 'width', 'height'],
              },
              page: {
                type: 'object',
                properties: {
                  path: { type: 'string' },
                  referrer: { type: 'string' },
                  search: { type: 'string' },
                  title: { type: 'string' },
                  url: { type: 'string' },
                },
                required: ['path', 'referrer', 'search', 'title', 'url'],
              },
              library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
            },
            required: ['locale', 'userAgent', 'screen', 'page', 'library'],
          },
        },
        required: ['anonymousId', 'userId', 'timestamp', 'name', 'properties', 'context'],
      },
      tag: {
        type: 'object',
        properties: {
          anonymousId: { type: 'string' },
          userId: { type: 'string' },
          timestamp: { type: 'string' },
          name: { type: 'string' },
          properties: {
            type: 'object',
            properties: {
              tag: { type: 'object', properties: { id: { type: 'number' }, title: { type: 'string' } }, required: ['id', 'title'] },
              category: { type: 'string' },
            },
            required: ['tag', 'category'],
          },
          context: {
            type: 'object',
            properties: {
              locale: { type: 'string' },
              userAgent: { type: 'string' },
              screen: {
                type: 'object',
                properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                required: ['devicePixelRatio', 'width', 'height'],
              },
              page: {
                type: 'object',
                properties: {
                  path: { type: 'string' },
                  referrer: { type: 'string' },
                  search: { type: 'string' },
                  title: { type: 'string' },
                  url: { type: 'string' },
                },
                required: ['path', 'referrer', 'search', 'title', 'url'],
              },
              library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
            },
            required: ['locale', 'userAgent', 'screen', 'page', 'library'],
          },
        },
        required: ['anonymousId', 'userId', 'timestamp', 'name', 'properties', 'context'],
      },
      article: {
        type: 'object',
        properties: {
          anonymousId: { type: 'string' },
          userId: { type: 'string' },
          timestamp: { type: 'string' },
          name: { type: 'string' },
          properties: {
            type: 'object',
            properties: {
              article: {
                type: 'object',
                properties: { id: { type: 'number' }, title: { type: 'string' }, ctaURL: {}, ctaText: {} },
                required: ['id', 'title', 'ctaURL', 'ctaText'],
              },
              category: { type: 'string' },
            },
            required: ['article', 'category'],
          },
          context: {
            type: 'object',
            properties: {
              locale: { type: 'string' },
              userAgent: { type: 'string' },
              screen: {
                type: 'object',
                properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                required: ['devicePixelRatio', 'width', 'height'],
              },
              page: {
                type: 'object',
                properties: {
                  path: { type: 'string' },
                  referrer: { type: 'string' },
                  search: { type: 'string' },
                  title: { type: 'string' },
                  url: { type: 'string' },
                },
                required: ['path', 'referrer', 'search', 'title', 'url'],
              },
              library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
            },
            required: ['locale', 'userAgent', 'screen', 'page', 'library'],
          },
        },
        required: ['anonymousId', 'userId', 'timestamp', 'name', 'properties', 'context'],
      },
    },
    schema: {
      rendered: {
        'img-banner': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                ctaURL: { type: 'string' },
                imgBanner: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, ctaURL: { type: 'string' }, ctaText: { type: 'null' }, image: { type: 'string' } },
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: { type: 'object', properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } } },
              },
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: { type: 'object', properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } } },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                },
                library: { type: 'object', properties: { name: { type: 'string' } } },
              },
            },
          },
        },
        'content-category': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                ctaURL: { type: 'string' },
                contentCategory: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, label: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'label', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'ctaURL', 'contentCategory', 'section', 'sectionPageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        section: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                pageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'section', 'pageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        offer: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                offer: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    offerText: { type: 'string' },
                    ctaURL: { type: 'string' },
                    redirectUrl: { type: 'string' },
                    ctaText: { type: 'string' },
                    brandId: { type: 'integer' },
                    brandName: { type: 'string' },
                  },
                  required: ['id', 'offerText', 'ctaURL', 'redirectUrl', 'ctaText', 'brandId', 'brandName'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'offer', 'section', 'sectionPageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        brand: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                ctaURL: { type: 'string' },
                brand: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, name: { type: 'string' }, ctaURL: { type: 'string' } },
                  required: ['id', 'name', 'ctaURL'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'ctaURL',
                'brand',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'more-content-card': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                ctaPosition: { type: 'string' },
                ctaText: { type: 'string' },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                pageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'ctaPosition', 'ctaText', 'section', 'pageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        logo: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                title: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'title', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'product-feature': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                productFeature: {
                  type: 'object',
                  properties: { id: { type: 'number' }, title: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'title', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'number' }, title: { type: 'string' }, scrolling: { type: 'string' }, cardSize: {}, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'productFeature',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'footer-link': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                ctaURL: { type: 'string' },
                title: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'ctaURL', 'title', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        article: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                article: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'title', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'article',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'content-coupon': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                contentType: { type: 'string' },
                contentDetails: {
                  type: 'object',
                  properties: {
                    id: { type: 'number' },
                    offerText: { type: 'string' },
                    ctaURL: { type: 'string' },
                    ctaText: { type: 'string' },
                    brandId: { type: 'number' },
                    brandName: { type: 'string' },
                  },
                  required: ['id', 'offerText', 'ctaURL', 'ctaText', 'brandId', 'brandName'],
                },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'contentType', 'contentDetails', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
      },
      mouseout: {
        'img-banner': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                ctaURL: { type: 'string' },
                imgBanner: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, ctaURL: { type: 'string' }, ctaText: { type: 'null' }, image: { type: 'string' } },
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: { type: 'object', properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } } },
              },
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: { type: 'object', properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } } },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                },
                library: { type: 'object', properties: { name: { type: 'string' } } },
              },
            },
          },
        },
        'content-category': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                ctaURL: { type: 'string' },
                contentCategory: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, label: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'label', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'ctaURL', 'contentCategory', 'section', 'sectionPageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        section: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                pageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'section', 'pageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        offer: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                offer: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    offerText: { type: 'string' },
                    ctaURL: { type: 'string' },
                    redirectUrl: { type: 'string' },
                    ctaText: { type: 'string' },
                    brandId: { type: 'integer' },
                    brandName: { type: 'string' },
                  },
                  required: ['id', 'offerText', 'ctaURL', 'redirectUrl', 'ctaText', 'brandId', 'brandName'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'offer', 'section', 'sectionPageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        brand: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                ctaURL: { type: 'string' },
                brand: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, name: { type: 'string' }, ctaURL: { type: 'string' } },
                  required: ['id', 'name', 'ctaURL'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'ctaURL',
                'brand',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'more-content-card': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                ctaPosition: { type: 'string' },
                ctaText: { type: 'string' },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                pageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'ctaPosition', 'ctaText', 'section', 'pageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        logo: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                title: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'title', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'product-feature': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                productFeature: {
                  type: 'object',
                  properties: { id: { type: 'number' }, title: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'title', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'number' }, title: { type: 'string' }, scrolling: { type: 'string' }, cardSize: {}, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'productFeature',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'footer-link': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                ctaURL: { type: 'string' },
                title: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'ctaURL', 'title', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        article: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                article: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'title', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'article',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'content-coupon': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                contentType: { type: 'string' },
                contentDetails: {
                  type: 'object',
                  properties: {
                    id: { type: 'number' },
                    offerText: { type: 'string' },
                    ctaURL: { type: 'string' },
                    ctaText: { type: 'string' },
                    brandId: { type: 'number' },
                    brandName: { type: 'string' },
                  },
                  required: ['id', 'offerText', 'ctaURL', 'ctaText', 'brandId', 'brandName'],
                },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'contentType', 'contentDetails', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
      },

      mouseover: {
        'img-banner': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                ctaURL: { type: 'string' },
                imgBanner: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, ctaURL: { type: 'string' }, ctaText: { type: 'null' }, image: { type: 'string' } },
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: { type: 'object', properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } } },
              },
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: { type: 'object', properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } } },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                },
                library: { type: 'object', properties: { name: { type: 'string' } } },
              },
            },
          },
        },
        'content-category': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                ctaURL: { type: 'string' },
                contentCategory: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, label: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'label', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'ctaURL', 'contentCategory', 'section', 'sectionPageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        section: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                pageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'section', 'pageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        offer: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                offer: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    offerText: { type: 'string' },
                    ctaURL: { type: 'string' },
                    redirectUrl: { type: 'string' },
                    ctaText: { type: 'string' },
                    brandId: { type: 'integer' },
                    brandName: { type: 'string' },
                  },
                  required: ['id', 'offerText', 'ctaURL', 'redirectUrl', 'ctaText', 'brandId', 'brandName'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'offer', 'section', 'sectionPageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        brand: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                ctaURL: { type: 'string' },
                brand: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, name: { type: 'string' }, ctaURL: { type: 'string' } },
                  required: ['id', 'name', 'ctaURL'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'ctaURL',
                'brand',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'more-content-card': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                ctaPosition: { type: 'string' },
                ctaText: { type: 'string' },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                pageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'ctaPosition', 'ctaText', 'section', 'pageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        logo: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                title: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'title', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'product-feature': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                productFeature: {
                  type: 'object',
                  properties: { id: { type: 'number' }, title: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'title', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'number' }, title: { type: 'string' }, scrolling: { type: 'string' }, cardSize: {}, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'productFeature',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'footer-link': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                ctaURL: { type: 'string' },
                title: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'ctaURL', 'title', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        article: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                article: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'title', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'article',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'content-coupon': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                contentType: { type: 'string' },
                contentDetails: {
                  type: 'object',
                  properties: {
                    id: { type: 'number' },
                    offerText: { type: 'string' },
                    ctaURL: { type: 'string' },
                    ctaText: { type: 'string' },
                    brandId: { type: 'number' },
                    brandName: { type: 'string' },
                  },
                  required: ['id', 'offerText', 'ctaURL', 'ctaText', 'brandId', 'brandName'],
                },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'contentType', 'contentDetails', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
      },
      click: {
        'img-banner': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                ctaURL: { type: 'string' },
                imgBanner: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, ctaURL: { type: 'string' }, ctaText: { type: 'null' }, image: { type: 'string' } },
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: { type: 'object', properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } } },
              },
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: { type: 'object', properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } } },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                },
                library: { type: 'object', properties: { name: { type: 'string' } } },
              },
            },
          },
        },
        'content-category': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                ctaURL: { type: 'string' },
                contentCategory: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, label: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'label', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'ctaURL', 'contentCategory', 'section', 'sectionPageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        section: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                pageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'section', 'pageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        offer: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                offer: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    offerText: { type: 'string' },
                    ctaURL: { type: 'string' },
                    redirectUrl: { type: 'string' },
                    ctaText: { type: 'string' },
                    brandId: { type: 'integer' },
                    brandName: { type: 'string' },
                  },
                  required: ['id', 'offerText', 'ctaURL', 'redirectUrl', 'ctaText', 'brandId', 'brandName'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'offer', 'section', 'sectionPageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        brand: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                ctaURL: { type: 'string' },
                brand: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, name: { type: 'string' }, ctaURL: { type: 'string' } },
                  required: ['id', 'name', 'ctaURL'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'ctaURL',
                'brand',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'more-content-card': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                ctaPosition: { type: 'string' },
                ctaText: { type: 'string' },
                section: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, scrolling: { type: 'string' }, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'template'],
                },
                pageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'ctaPosition', 'ctaText', 'section', 'pageType', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        logo: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                title: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'title', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'product-feature': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                productFeature: {
                  type: 'object',
                  properties: { id: { type: 'number' }, title: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'title', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: { id: { type: 'number' }, title: { type: 'string' }, scrolling: { type: 'string' }, cardSize: {}, template: { type: 'string' } },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'productFeature',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'footer-link': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                ctaURL: { type: 'string' },
                title: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'ctaURL', 'title', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        article: {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                associatedContentId: { type: 'string' },
                associatedContentType: { type: 'string' },
                article: {
                  type: 'object',
                  properties: { id: { type: 'integer' }, title: { type: 'string' }, ctaURL: { type: 'string' }, ctaText: { type: 'string' } },
                  required: ['id', 'title', 'ctaURL', 'ctaText'],
                },
                section: {
                  type: 'object',
                  properties: {
                    id: { type: 'integer' },
                    title: { type: 'string' },
                    scrolling: { type: 'string' },
                    cardSize: { type: 'null' },
                    template: { type: 'string' },
                  },
                  required: ['id', 'title', 'scrolling', 'cardSize', 'template'],
                },
                sectionPageType: { type: 'string' },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: [
                'property',
                'realEstateId',
                'associatedContentId',
                'associatedContentType',
                'article',
                'section',
                'sectionPageType',
                'template',
                'element',
              ],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'integer' }, width: { type: 'integer' }, height: { type: 'integer' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
        'content-coupon': {
          type: 'object',
          properties: {
            anonymousId: { type: 'string' },
            userId: { type: 'string' },
            timestamp: { type: 'string' },
            event: { type: 'string' },
            properties: {
              type: 'object',
              properties: {
                property: { type: 'string' },
                realEstateId: { type: 'string' },
                contentType: { type: 'string' },
                contentDetails: {
                  type: 'object',
                  properties: {
                    id: { type: 'number' },
                    offerText: { type: 'string' },
                    ctaURL: { type: 'string' },
                    ctaText: { type: 'string' },
                    brandId: { type: 'number' },
                    brandName: { type: 'string' },
                  },
                  required: ['id', 'offerText', 'ctaURL', 'ctaText', 'brandId', 'brandName'],
                },
                template: { type: 'string' },
                element: {
                  type: 'object',
                  properties: { tagName: { type: 'string' }, id: { type: 'string' }, classList: { type: 'string' } },
                  required: ['tagName', 'id', 'classList'],
                },
              },
              required: ['property', 'realEstateId', 'contentType', 'contentDetails', 'template', 'element'],
            },
            context: {
              type: 'object',
              properties: {
                locale: { type: 'string' },
                userAgent: { type: 'string' },
                screen: {
                  type: 'object',
                  properties: { devicePixelRatio: { type: 'number' }, width: { type: 'number' }, height: { type: 'number' } },
                  required: ['devicePixelRatio', 'width', 'height'],
                },
                page: {
                  type: 'object',
                  properties: {
                    path: { type: 'string' },
                    referrer: { type: 'string' },
                    search: { type: 'string' },
                    title: { type: 'string' },
                    url: { type: 'string' },
                  },
                  required: ['path', 'referrer', 'search', 'title', 'url'],
                },
                library: { type: 'object', properties: { name: { type: 'string' } }, required: ['name'] },
              },
              required: ['locale', 'userAgent', 'screen', 'page', 'library'],
            },
          },
          required: ['anonymousId', 'userId', 'timestamp', 'event', 'properties', 'context'],
        },
      },
    },
  },
  defaultYearsRangeForMonthFilter: [2016, new Date().getFullYear()],
  spendAnalyzerEnabled: false,
  spendAnalyzerColors: ['rgb(33,119,177)', 'rgb(241,90,41)', 'rgb(213,42,45)', 'rgb(48,160,57)', 'rgb(188,189,59)', 'rgb(255,164,0)', 'rgb(196,237,221)'],
  ENCRYPTION_KEY: '',
  FIELDS_TO_ENCRYPT: ['email', 'password'],
};
