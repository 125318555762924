import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-error-state',
  templateUrl: './error-state.component.html',
  styleUrls: ['./error-state.component.scss'],
})
export class ErrorStateComponent implements OnInit, OnChanges {
  @Input() retryError: any;
  @Input() trackData: any;
  @Input() idContext: string;
  @Output() retryTask = new EventEmitter();

  title: string;
  description?: string;
  imageUrl: string;
  allowRetry: boolean;

  constructor() {}

  ngOnInit() {
    this._init();
  }

  ngOnChanges() {
    this._init();
  }

  retry() {
    this.retryTask.next();
  }

  private _init() {
    if (this.retryError && this.retryError.error && typeof this.retryError.error === 'object') {
      this.title = this.retryError.error.title;
      this.description = this.retryError.error.desc;
      this.imageUrl = this.retryError.error.image;
      this.allowRetry = this.retryError.error.allowRetry;
    }
    if (this.title === undefined) {
      this.title = 'OOPS';
    }
    if (this.description === undefined) {
      this.description = 'Sorry, we could not catch that!';
    }
    if (this.imageUrl === undefined) {
      this.imageUrl = '/assets/images/core/error.svg';
    }
  }
}
