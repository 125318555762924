import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { TypeaheadConfig } from 'ngx-bootstrap/typeahead';
// import { isBs3 } from 'ngx-bootstrap/utils';

import { noop, Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { SearchByBrandService } from '../../services/search-by-brand.service';
import { environment } from '../../../environments/environment';

export function getTypeaheadConfig(): TypeaheadConfig {
  return Object.assign(new TypeaheadConfig(), { cancelRequestOnFocusLost: true });
}

interface AutoSuggestBrand {
  id: number;
  name: string;
  ctaURL: string;
  image?: string;
}

@Component({
  selector: 'app-search-by-brand',
  templateUrl: './search-by-brand.component.html',
  styleUrls: ['./search-by-brand.component.scss'],
  providers: [{ provide: TypeaheadConfig, useFactory: getTypeaheadConfig }],
})
export class SearchByBrandComponent implements OnInit {
  // isBs3 = isBs3();
  isFocused: boolean = false;
  q: string = '';
  noResult: boolean = false;
  typeaheadLoading: boolean = false;
  suggestions$: Observable<AutoSuggestBrand[]>;
  errorMessage: string;
  searchInputPlaceholderText = environment.searchInputPlaceholderText;

  @ViewChild('searchWrapper') searchWrapper: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(private renderer: Renderer2, private searchByBrandService: SearchByBrandService, private router: Router) {
    /**
     * This events get called by all clicks on the page
     */
    this.renderer.listen('window', 'click', (e: any) => {
      if (this.isFocused && e.path && e.path instanceof Array && e.path.indexOf(this.searchWrapper.nativeElement) === -1) {
        this.isFocused = false;
      }
      this.searchByBrandService.setFocus(this.isFocused);
    });
  }

  ngOnInit() {
    this._init();
  }

  toggleFocus() {
    this.isFocused = !this.isFocused;
    this.searchByBrandService.setFocus(this.isFocused);
  }

  inputClicked() {
    if (!this.isFocused) {
      this.isFocused = true;
      this.searchByBrandService.setFocus(this.isFocused);
      // For SSR (server side rendering) this is not safe.
      // Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
      this.searchInput.nativeElement.focus();
    }
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  clear() {
    if (this.q) {
      this.q = '';
      this.isFocused = true;
      this.searchByBrandService.setFocus(this.isFocused);
    } else {
      this.isFocused = false;
      this.searchByBrandService.setFocus(this.isFocused);
    }
  }

  typeaheadOnSelect(event) {
    this.router.navigateByUrl(event.item.ctaURL);
    this.isFocused = false;
    this.searchByBrandService.setFocus(this.isFocused);
  }

  private _init() {
    this.searchByBrandService.setFocus(this.isFocused);

    this.suggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.q);
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.searchByBrandService.autosuggest(query).pipe(
            map((resp: any) => {
              return resp && resp.data && resp.data.result && resp.data.result instanceof Array ? <AutoSuggestBrand[]>resp.data.result : [];
            }),
            tap(
              () => noop,
              (err) => {
                // in case of http error
                this.errorMessage = (err && err.message) || 'Something went wrong';
                this.typeaheadLoading = false;
              }
            )
          );
        }
        return of([]);
      })
    );
  }
}
