import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderModule } from './loader/loader.module';
import { NavigationModule } from './navigation/navigation.module';
import { WINDOW_PROVIDERS } from './providers/window.provider';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { LazyLoadImgModule } from './lazy-load-img/lazy-load-img.module';
import { LazyImgDirective } from './directives/lazy-img.directive';
import { TrackViewPortEventsDirective } from './directives/track.directive';
import { TrackMouseEventsDirective } from './directives/track.directive';
import { InViewportModule } from 'ng-in-viewport';
import { SanitizeTrackingDataPipe } from './pipes/sanitize-tracking-data.pipe';
import { NoLogoModule } from './no-logo/no-logo.module';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { realEstateIdPipe } from './pipes/real-estate-identifier.pipe';
import { spaceHyphenPipe } from './pipes/string-space-hyphen.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SwiperDirective } from './directives/swiper.directive';

@NgModule({
  declarations: [
    CopyClipboardDirective,
    SafeStylePipe,
    SafeHtmlPipe,
    LazyImgDirective,
    TrackViewPortEventsDirective,
    TrackMouseEventsDirective,
    SanitizeTrackingDataPipe,
    DateFormatPipe,
    realEstateIdPipe,
    spaceHyphenPipe,
    SwiperDirective
  ],
  imports: [CommonModule, LoaderModule, NavigationModule, LazyLoadImgModule, InViewportModule],
  providers: [WINDOW_PROVIDERS],
  exports: [
    LoaderModule,
    NavigationModule,
    CopyClipboardDirective,
    SafeStylePipe,
    SafeHtmlPipe,
    LazyLoadImgModule,
    LazyImgDirective,
    TrackViewPortEventsDirective,
    TrackMouseEventsDirective,
    InViewportModule,
    SanitizeTrackingDataPipe,
    NoLogoModule,
    DateFormatPipe,
    realEstateIdPipe,
    spaceHyphenPipe,
    SwiperDirective
  ],
})
export class CoreModule {}
