import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, of, BehaviorSubject } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';
import moment from 'moment';

import { environment } from 'src/environments/environment';

import { SpendAnalyzerService } from '../../services/spend-analyzer.service';
import { ContentService } from 'src/app/services/content.service';
import { PageTitleService } from 'src/app/services/page-title.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { NavigationService } from 'src/app/core/navigation/navigation.service';

import { ContentSection } from '../../types/content-section.class';

@Component({
  selector: 'app-spend-analyzer',
  templateUrl: './spend-analyzer.component.html',
  styleUrls: ['./spend-analyzer.component.scss'],
})
export class SpendAnalyzerComponent implements OnInit {
  spendAnalyzer = environment.spendAnalyzer.page;
  currentDate = new Date();
  isLoading: boolean = false;
  isDataAvailable: boolean = false;
  isChartLoaded: boolean = false;
  retryError: Error;
  get isError(): boolean {
    return !!this.retryError;
  }


  onError(err){
    this.retryError = err
  }
  pageType: string = 'spend-analyzer';

  defaultYearMonth: number;
  
  categoryWiseSpends: any = [];

  colors = environment.spendAnalyzerColors;

  public pieChartColors = [
    { 
      backgroundColor: this.colors ,
      borderWidth:0,
    },
  ];
  
  pieChart: any = {
    responsive: true,
    maintainAspectRatio: false,
    width: 400,
    height: 400,
    data: [],
    labels: [],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      hoverOffset: 4,
      hoverBorderWidth: 0,
      borderWidth:0,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
      },
    },
  };

  contentType: string;
  categories: string = '';
  contentId: string;
  

  contentDetails: any = {};

  isCouponCodeCopied: boolean = false;
  isOfferExpired: boolean = false;
  isCouponRevealed: boolean = true;

  offerEndDateLabel: string = environment.offerEndDateLabel;
  showBankConstruct: boolean = environment.showBankConstruct;
  bankNameWithVariant: string = environment.bankNameWithVariant;
  expiredOfferCtaText: string = environment.expiredOfferCtaText;
  expiredOfferDescription: string = environment.expiredOfferDescription;

  private _subscription$: Subscription = new Subscription();

  loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  cards: BehaviorSubject<any[]> = new BehaviorSubject([]);
  page: number = 1;
  hasNextPage: boolean = true;
  isEmpty: boolean = false;

  constructor(
    private spendAnalyzerService: SpendAnalyzerService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private router: Router,
    private pageTitleService: PageTitleService,
    private _analyticsService: AnalyticsService,
    private navigationService: NavigationService
  ) {}

  ngAfterViewInit() {
    this.updateChartSize();
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateChartSize.bind(this));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateChartSize();
  }

  private updateChartSize() {
    // Update chart size based on the container size
    const container = document.getElementById('chart-container');
    if (container) {
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      this.pieChart.width = containerWidth;
      this.pieChart.height = containerHeight;
    }
  }

  toggleCollapse(index: number) {
    this.categoryWiseSpends[index].isExpanded = !this.categoryWiseSpends[index].isExpanded;
    let categories = this.categoryWiseSpends[index].categories;
    this.getContentDetails(categories);
  }

  getFilteredData(yearMonth) {
    this.spendAnalyzerService.getAnalyzerSpends(yearMonth).subscribe(
      (resp) => {
        if (resp && resp.data && resp.data.data) {
          this.isDataAvailable = true;
          resp.data.data.sort((a, b) => b.total_spends - a.total_spends);
          for (let spend of resp.data.data) {
            this.pieChart.data.push(Math.round(spend.percentage * 10000) / 100);
            this.pieChart.labels.push(spend.name);
            this.categoryWiseSpends.push({
              title: spend.name,
              value: spend.total_spends,
              percentage: Math.round(spend.percentage * 10000) / 100,
              icon: spend.logo,
              isExpanded: false,
              subIndustries: spend.sub_industry_id,
              categories: spend.category_id,
            });
          }
        } else {
          this.isDataAvailable = false;
        }
        this.isChartLoaded = true;
        this.isLoading = false;
      },
      (err) => {
        this.retryError = err;
        this.isLoading = false;
      }
    );
  }


  
  getContentDetails(categories: number[]) {
    this.categories = categories.join(',');
    this.contentType = 'category';
    if (this._subscription$) {
      this._subscription$.unsubscribe();
    }
    let filterParams = {
      category: this.categories,
    };
    this._subscription$ = this.contentService.getRelatedContentDetails(filterParams).subscribe((res) => {
      
      this.contentDetails = {
        content: res.data.result.content,
      };
    });

  }

  ngOnInit(): void {}

  onClose() {
    window.close();
  }

  monthSelected(event: any) {
    this.categoryWiseSpends = [];
    this.pieChart.data = [];
    this.pieChart.labels = [];
    let selectedYear = event.year;
    let selectedMonth = moment().month(event.month).format('MM');
    let selectedYearMonth = `${selectedYear}${selectedMonth}`;
    this.getFilteredData(selectedYearMonth);
    // this.getContents(selectedYearMonth);
  }
}
