import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';

@Directive({
  selector: '[appSwiper]'
})
export class SwiperDirective implements AfterViewInit {
  @Input() swiperOptions!: SwiperOptions;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const swiperElement = this.el.nativeElement;
    if (swiperElement instanceof HTMLElement) {
      new Swiper(swiperElement, this.swiperOptions);
    } else {
      console.error('Swiper element is not an HTMLElement');
    }
  }
}
