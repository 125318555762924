<ng-container *ngIf="!isModal">
  <ng-container *ngTemplateOutlet="disclaimerTextTemplate; context: { isModal: false, disclaimerText: disclaimerText }"></ng-container>
</ng-container>

<ng-container *ngIf="isModal">
  <div class="modal-header p-2">
    <h5 class="modal-title text-center w-100" id="disclaimer-model-title">Disclaimer</h5>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="disclaimerTextTemplate; context: { isModal: true, disclaimerText: disclaimerText }"></ng-container>
  </div>
</ng-container>

<ng-template #disclaimerTextTemplate let-isModal="isModal" let-disclaimerText="disclaimerText">
  <div class="d-flex flex-column text-justify copyright-container" [ngClass]="{ 'disclaimer-container px-4 py-2 mb-2': !isModal }">
    <p class="disclaimer-text mb-2" id="disclaimer-text{{ isModal ? '-modal' : '' }}" [innerHTML]="disclaimerText | safeHtml"></p>
    <p class="disclaimer-text mb-0 text-center" *ngIf="isModal">
      <button class="btn btn-ternary" (click)="bsModalRef.hide()" id="disclaimer-model-cta" aria-labelledby="OK">OK</button>
    </p>
  </div>
</ng-template>
