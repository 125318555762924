import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewGoliveComponent } from './preview-golive.component';
import { CoreModule } from 'src/app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PreviewGoliveComponent],
  imports: [CommonModule, CoreModule, FormsModule, ReactiveFormsModule],
  exports: [PreviewGoliveComponent],
})
export class PreviewGoliveModule {}
