import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PageHeaderComponent } from './page-header.component';
import { CoreModule } from '../../core/core.module';
import { SearchByBrandModule } from '../search-by-brand/search-by-brand.module';
import { MenuModule } from '../../components/menu/menu.module';

@NgModule({
  declarations: [PageHeaderComponent],
  imports: [CommonModule, RouterModule, CoreModule, SearchByBrandModule, MenuModule],
  exports: [PageHeaderComponent],
})
export class PageHeaderModule {}
