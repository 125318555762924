<div class="preview-go-live-container" *ngIf="isPreviewGoLiveEnabled">
  <a
    class="btn btn-primary btn-preview-go-live-floating"
    id="promote-preview-to-production-button"
    href="javascript:void(0)"
    (click)="onPreviewGoLiveAction()"
    aria-label="Go Live"
  >
    Go Live
  </a>
</div>
<ng-template #modalPreviewGoLive>
  <div class="row preview-go-live-modal-content-container step-{{ step }} status-{{ goLiveStatus }} justify-content-center">
    <ng-container *ngIf="step < 3">
      <div class="card-body text-center" *ngIf="isLoading">
        <app-loader></app-loader>
      </div>
      <div class="col-12 text-center py-4" *ngIf="!isLoading">
        <div class="modal-header p-2">
          <h4 class="modal-title text-center w-100" id="disclaimer-model-title">Let's go live!</h4>
        </div>
        <div class="modal-body">
          <p class="description-text">This process will immediately make your changes live</p>
          <p class="disclaimer-text">This operation CAN NOT be rolled-back</p>
          <p class="confirmation-text">Continue only if you are absolutely sure!</p>
          <div *ngIf="step === 1" class="text-center">
            <button type="button" class="btn btn-primary mb-2" id="preview-go-live-btn" (click)="step = 2" aria-labelledby="Proceed">PROCEED</button>
            <br />
            <a href="javascript: void(0)" (click)="bsModalRef.hide()" class="btn btn-sm btn-link" aria-label="Cancel">Cancel</a>
          </div>
          <div class="card-body pt-0" *ngIf="step === 2">
            <div class="card-content">
              <div class="card-body p-0 mt-2">
                <form class="form" [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()" *ngIf="!isLoading">
                  <div class="form-body">
                    <div class="form-group text-left">
                      <label for="email" class="label text-muted">Email</label>
                      <input
                        type="email"
                        autocomplete="off"
                        formControlName="email"
                        id="email"
                        required
                        class="form-control min-height-36"
                        placeholder="email id"
                        [ngClass]="{
                          'is-invalid': loginFormControls.email.invalid && (loginFormControls.email.dirty || loginFormControls.email.touched)
                        }"
                      />
                      <span class="invalid-feedback" *ngIf="loginFormControls.email.errors && loginFormControls.email.errors.required"
                        >Please enter <strong>email</strong></span
                      >
                      <span class="invalid-feedback" *ngIf="loginFormControls.email.errors && loginFormControls.email.errors.pattern"
                        >Please enter a valid <strong>email</strong></span
                      >
                      <span class="invalid-feedback" *ngIf="loginFormControls.email.errors && loginFormControls.email.errors.maxlength">Email too long</span>
                    </div>
                    <div class="form-group text-left">
                      <label for="password" class="label text-muted">Password</label>
                      <input
                        type="password"
                        placeholder="********"
                        autocomplete="off"
                        formControlName="password"
                        id="password"
                        class="form-control min-height-36"
                        required
                        [ngClass]="{
                          'is-invalid': loginFormControls.password.invalid && (loginFormControls.password.dirty || loginFormControls.password.touched)
                        }"
                      />
                      <span class="invalid-feedback">Please enter a valid password</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center mt-2">
                      <button
                        type="submit"
                        id="preview-go-live-login-form-submit"
                        class="btn btn-primary mb-2"
                        [disabled]="!loginForm.valid"
                        aria-labelledby="Go live"
                      >
                        GO LIVE
                      </button>
                      <br />
                      <a href="javascript: void(0)" (click)="bsModalRef.hide()" class="btn btn-sm btn-link" aria-label="Cancel">Cancel</a>
                    </div>
                  </div>
                </form>
              </div>
              <div class="card-body text-center" *ngIf="isLoading">
                <app-loader></app-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="step === 3">
      <div class="col-12 text-center py-4">
        <div class="modal-body">
          <div class="api-response-image text-center">
            <div class="success-checkmark" *ngIf="goLiveStatus === 'success'">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
            <svg class="error-crossmark warning" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" *ngIf="goLiveStatus === 'warning'">
              <circle class="crossmark-circle" cx="26" cy="26" r="25" fill="none" />
              <path class="crossmark-cross" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8" />
            </svg>
            <svg class="error-crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" *ngIf="goLiveStatus === 'error'">
              <circle class="crossmark-circle" cx="26" cy="26" r="25" fill="none" />
              <path class="crossmark-cross" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8" />
            </svg>
          </div>
          <p class="api-response-text status-{{ goLiveStatus }} mt-3">
            {{ goLiveResponseMessage }}
          </p>
          <div>
            <button type="button" class="btn btn-ternary btn-small my-3" id="preview-go-live-success-btn" (click)="closeModal()" aria-labelledby="OK">
              OK
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <span class="animated-border top"></span>
    <span class="animated-border right"></span>
    <span class="animated-border bottom"></span>
    <span class="animated-border left"></span>
  </div>
</ng-template>
