import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabbed-layout',
  templateUrl: './tabbed-layout.component.html',
  styleUrls: ['./tabbed-layout.component.scss'],
})
export class TabbedLayoutComponent implements OnInit {
  tabs: any[] = [
    // {
    //   id: 'home',
    //   label: 'For you',
    //   icon: 'icon icon-for-you',
    //   url: '/home',
    // },
    // // {
    // //   id: 'offers',
    // //   label: 'Offers',
    // //   icon: 'icon icon-offers',
    // //   url: '/offers',
    // // },
    // {
    //   id: 'discover',
    //   label: 'Discover',
    //   icon: 'icon icon-discover',
    //   url: '/discover',
    // },
  ];

  constructor() {}

  ngOnInit() {}
}
