import { environment } from "src/environments/environment";
import Ajv from "ajv"

const ajv = new Ajv()

export const rudderanalytics = {
  _baseUrl: '',
  _headers: {
    'Content-Type': 'application/json',
  },
  _cookiePrefix: '_a_g101.',
  getBaseContext: function () {
    return {
      locale: navigator.language,
      userAgent: navigator.userAgent,
      screen: {
        devicePixelRatio: window.devicePixelRatio,
        width: window.screen.availWidth,
        height: window.screen.availHeight,
      },
      page: {
        path: window.location.pathname,
        referrer: document.referrer,
        search: window.location.search,
        title: document.title,
        url: window.location.href,
      },
      library: {
        name: 'g101',
      },
    };
  },
  load: function (writeKey, serverUrl, options) {
    this._baseUrl = serverUrl;
    this._headers['Authorization'] = 'Basic ' + btoa(writeKey + ':');
  },
  ready: function (callback) {
    callback = callback || function () {};
    callback();
  },
  identify: function (userId, traits, options, callback) {
    if (typeof options === 'function') {
      callback = options;
      options = null;
    }
    if (typeof traits === 'function') {
      callback = traits;
      options = null;
      traits = null;
    }
    if (typeof userId === 'object') {
      options = traits;
      traits = userId;
      userId = this.userId;
    }
    if (userId && this.userId && userId !== this.userId) {
      this.reset();
    }
    this.setUserId(userId);
    callback = callback || function () {};
    var payload = {
      anonymousId: this.getAnonymousId(),
      userId: this.userId,
      timestamp: new Date().toISOString(),
      traits: traits,
      context: this.getBaseContext(),
    };
    fetch(this._baseUrl + '/v1/identify', {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload),
    })
      .then(callback)
      .catch(callback);
  },
  page: function (category, name, properties, options, callback) {
    if (typeof options === 'function') {
      callback = options;
      options = null;
    }
    if (typeof properties === 'function') {
      callback = properties;
      options = properties = null;
    }
    if (typeof name === 'function') {
      callback = name;
      options = properties = name = null;
    }
    if (typeof category === 'object') {
      options = name;
      properties = category;
      name = category = null;
    }
    if (typeof name === 'object') {
      options = properties;
      properties = name;
      name = null;
    }
    if (typeof category === 'string' && typeof name !== 'string') {
      name = category;
      category = null;
    }
    callback = callback || function () {};
    properties = properties || {};
    properties.category = category;
    var payload = {
      anonymousId: this.getAnonymousId(),
      userId: this.userId,
      timestamp: new Date().toISOString(),
      name: name,
      properties: properties,
      context: this.getBaseContext(),
    };

    if(environment.isPreviewGoLiveEnabled){
      if(payload.name && environment.schema.page[payload.name]){
        const s = environment.schema.page[payload.name];
        const validate = ajv.compile(s);
        if (!validate(payload)) {
          for (const e of validate.errors){
            console.log(payload.name, '|', e.keyword ,'|',JSON.stringify(e.params),'|',e.dataPath,'|',e.message, payload)
          }
        } 
      }
    }

    fetch(this._baseUrl + '/v1/page', {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload),
    })
      .then(callback)
      .catch(callback);
  },
  track: function (event, properties, options, callback) {
    if (typeof options === 'function') {
      callback = options;
      options = null;
    }
    if (typeof properties === 'function') {
      callback = properties;
      options = null;
      properties = null;
    }
    callback = callback || function () {};
    var payload = {
      anonymousId: this.getAnonymousId(),
      userId: this.userId,
      timestamp: new Date().toISOString(),
      event: event,
      properties: properties,
      context: this.getBaseContext(),
    };

    if(environment.isPreviewGoLiveEnabled){
      if(payload.event && environment.schema.schema[payload.event]){
        const s = environment.schema.schema[payload.event][payload.properties.property];
        if(s){
          const validate = ajv.compile(s);
          if (!validate(payload)) {
            for (const e of validate.errors){
              console.log(payload.event, '|', e.keyword ,'|',JSON.stringify(e.params),'|',e.dataPath,'|',e.message, payload)
            }
          } 
        }
      }
    }



    fetch(this._baseUrl + '/v1/track', {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload),
    })
      .then(callback)
      .catch(callback);
  },
  alias: function (to, from, options, callback) {
    if (typeof options === 'function') {
      callback = options;
      options = null;
    }
    if (typeof from === 'function') {
      callback = from;
      options = null;
      from = null;
    }
    if (typeof from === 'object') {
      options = from;
      from = null;
    }
    callback = callback || function () {};
    var payload = {
      anonymousId: this.getAnonymousId(),
      userId: this.userId,
      timestamp: new Date().toISOString(),
      previousId: from,
      context: this.getBaseContext(),
    };
    fetch(this._baseUrl + '/v1/alias', {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload),
    })
      .then(callback)
      .catch(callback);
  },
  group: function (groupId, traits, options, callback) {
    if (typeof options === 'function') {
      callback = options;
      options = null;
    }
    if (typeof traits === 'function') {
      callback = traits;
      options = null;
      traits = null;
    }
    if (typeof groupId === 'object') {
      options = traits;
      traits = groupId;
      groupId = this.groupId;
    }
    callback = callback || function () {};
    var payload = {
      anonymousId: this.getAnonymousId(),
      userId: this.userId,
      timestamp: new Date().toISOString(),
      groupId: groupId,
      traits: traits,
      context: this.getBaseContext(),
    };
    fetch(this._baseUrl + '/v1/group', {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload),
    })
      .then(callback)
      .catch(callback);
  },
  reset: function () {
    this.anonymousId = '';
    this.userId = '';
    this.eraseKeyValue(this._cookiePrefix + 'a');
    this.eraseKeyValue(this._cookiePrefix + 'u');
  },
  getAnonymousId: function () {
    this.anonymousId = this.getValue(this._cookiePrefix + 'a');
    if (!this.anonymousId) {
      this.setAnonymousId();
    }
    return this.anonymousId;
  },
  setAnonymousId: function (anonymousId) {
    this.anonymousId = anonymousId || this.generateUUID();
    this.setKeyValue(this._cookiePrefix + 'a', this.anonymousId);
  },
  setUserId: function (userId) {
    this.userId = userId;
    this.setKeyValue(this._cookiePrefix + 'u', this.userId);
  },
  generateUUID: function () {
    // let BE set AnonymousId and use the same for all tracking
    return null;
  },
  setKeyValue: function (key, value) {
    window.localStorage.setItem(key, value);
  },
  getValue: function (key) {
    return window.localStorage.getItem(key) || null;
  },
  eraseKeyValue: function (key) {
    window.localStorage.removeItem(key);
  },
};
