import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  pageTitleChangedEvent: EventEmitter<string>;

  constructor(private browserTitleService: Title) {
    this.pageTitleChangedEvent = new EventEmitter();
  }

  setPageTitle(title?: string) {
    this.pageTitleChangedEvent.emit(title || environment.productName);
  }

  removePageTitle() {
    this.pageTitleChangedEvent.emit('');
  }

  setBrowserTitle(browserTitle?: string) {
    this.browserTitleService.setTitle(browserTitle ? browserTitle + ' | ' + environment.productName : environment.productName);
  }
}
