import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PageTitleService } from 'src/app/services/page-title.service';
import { SearchByBrandService } from 'src/app/services/search-by-brand.service';
import { environment } from 'src/environments/environment';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  @Input() showBackButton: boolean = false;
  @Input() showMenu: boolean = true;
  bankName: string = environment.bankName;
  toggleMenuBar: boolean = false;

  isSearchFocused: boolean = false;
  title: string;

  menuFeatureFlag: boolean = environment.featureFlags && environment.featureFlags.hasOwnProperty('showMenu') && environment.featureFlags.showMenu;
  searchFeatureFlag: boolean = environment.featureFlags && environment.featureFlags.hasOwnProperty('showSearchBar') && environment.featureFlags.showSearchBar;

  private _subscriptions$: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pageTitleService: PageTitleService,
    private searchByBrandService: SearchByBrandService,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this._init();
  }

  ngOnDestroy() {
    this._destroy();
  }

  back() {
    this.menuService.back();
  }

  collapseMenu() {
    this.toggleMenuBar = false;
  }

  private _init() {
    // Hide menu on page navigation
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === 'primary'))
      .subscribe(() => {
        this.toggleMenuBar = false;
      });
    this._subscriptions$.add(
      this.pageTitleService.pageTitleChangedEvent.subscribe((title: string) => {
        setTimeout(() => {
          // Prevents Error: "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
          // Previous value: '404 Not found'. Current value: 'Offers'"
          //
          // Error occurs when navigating from 404 page to any other tabbed layout page
          this.title = title;
        });
      })
    );
    this._subscriptions$.add(
      this.searchByBrandService.searchFocusEvent.subscribe((isFocused: boolean) => {
        this.isSearchFocused = isFocused;
      })
    );
  }

  private _destroy() {
    if (this._subscriptions$) {
      this._subscriptions$.unsubscribe();
    }
  }
}
