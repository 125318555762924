import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MenuServiceSeed {
  getMenu(): Observable<any> {
    const min = 100;
    const max = 1000;
    return of({
      success: true,
      data: {
        result: {
          menu: [
            {
              id: 1,
              label: 'All Categories',
              ctaURL: 'https://ilp.goals101.local/collection/all-categories/31',
            },
            {
              id: 2,
              label: 'All Brands',
              ctaURL: 'https://ilp.goals101.local/collection/all-categories/31',
            },
          ],
        },
      },
    }).pipe(delay(Math.floor(Math.random() * (max - min + 1) + min)));
  }
}
