import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

export class SearchByBrandServiceSeed {
  private static _brands = [
    {
      id: 1,
      name: 'Abcd',
      logo: 'https://g101-alpha-content-u.s3.ap-south-1.amazonaws.com/1593937085342-Adidas-logo.jpg',
      ctaURL: '/brand/abcd/1',
    },
    {
      id: 11,
      name: 'efgh',
      logo: 'https://g101-alpha-content-u.s3.ap-south-1.amazonaws.com/1593937085342-Adidas-logo.jpg',
      ctaURL: '/brand/efgh/11',
    },
    {
      id: 111,
      name: 'Abeg',
      logo: 'https://g101-alpha-content-u.s3.ap-south-1.amazonaws.com/1593937085342-Adidas-logo.jpg',
      ctaURL: '/brand/abeg/111',
    },
    {
      id: 1111,
      name: 'Absd2rffcd',
      logo: 'https://g101-alpha-content-u.s3.ap-south-1.amazonaws.com/1593937085342-Adidas-logo.jpg',
      ctaURL: '/brand/absd2rffcd/1111',
    },
    {
      id: 2,
      name: 'Abcsd3d',
      logo: 'https://g101-alpha-content-u.s3.ap-south-1.amazonaws.com/1593937085342-Adidas-logo.jpg',
      ctaURL: '/brand/abcsd3d/2',
    },
    {
      id: 3,
      name: 'sdffdfAbcd',
      logo: 'https://g101-alpha-content-u.s3.ap-south-1.amazonaws.com/1593937085342-Adidas-logo.jpg',
      ctaURL: '/brand/sdffdfabcd/3',
    },
    {
      id: 4,
      name: 'dfdfdfAbcd',
      logo: 'https://g101-alpha-content-u.s3.ap-south-1.amazonaws.com/1593937085342-Adidas-logo.jpg',
      ctaURL: '/brand/dfdfdfabcd/4',
    },
  ];

  constructor() {}

  private static _returnData(data: any): Observable<any> {
    const min = 100;
    const max = 1000;
    return of({
      success: true,
      data: data,
    }).pipe(delay(Math.floor(Math.random() * (max - min + 1) + min)));
  }

  static autosuggest(q?: string): Observable<any> {
    return SearchByBrandServiceSeed._returnData({
      result: SearchByBrandServiceSeed._brands.filter((b) => b.name.toLowerCase().indexOf(q.toLowerCase()) !== -1),
    });
  }
}
