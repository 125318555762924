<div class="row justify-content-center error-container" [id]="'error-container-' + idContext">
  <div class="col-12 col-sm-10 col-md-8 col-lg-6 text-center py-4">
    <picture>
      <img [src]="imageUrl" class="img-fluid" [alt]="title || 'Error'" [id]="'error-img-' + idContext" *ngIf="imageUrl" />
    </picture>
    <h3 class="title mt-4" *ngIf="title" [id]="'error-title-' + idContext">{{ title }}</h3>
    <p class="description mb-0" *ngIf="description" [id]="'error-desc-' + idContext" [ngClass]="{ 'mt-2': !!title, 'mt-4': !title }">{{ description }}</p>
    <button
      type="button"
      class="btn btn-primary mt-2"
      [id]="'error-btn-' + idContext"
      (click)="retry()"
      trackMouseEvents
      [trackData]="trackData"
      *ngIf="allowRetry"
      aria-labelledby="Retry"
    >
      <i class="icon icon-reload"></i> Retry
    </button>
  </div>
</div>
