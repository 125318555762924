<app-page-header [showBackButton]="false" [showMenu]="true"></app-page-header>
<div class="page-content tabbed-layout min-height-100" [ngClass]="{ 'has-no-tabs': !tabs?.length }">
  <router-outlet></router-outlet>
</div>
<div class="footer-tabs tabbed-footer" *ngIf="tabs?.length">
  <ul class="nav justify-content-center align-items-center">
    <li
      class="nav-item cursor-pointer"
      *ngFor="let tab of tabs"
      [id]="tab.id + '-tab'"
      [routerLink]="tab.url"
      routerLinkActive="active"
      trackMouseEvents
      [trackData]="{
        property: 'page-tab',
        label: tab.label,
        template: 'tabbed-layout'
      }"
    >
      <a class="nav-link text-uppercase" href="javascript:void(0);" [routerLink]="tab.url" [attr.aria-label]="tab.label">
        <i class="nav-icon {{ tab.icon }}" *ngIf="tab.icon"></i>
        {{ tab.label }}
      </a>
    </li>
  </ul>
</div>
<app-footer class="footer"></app-footer>
