import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoLogoComponent } from './no-logo.component';

@NgModule({
  declarations: [NoLogoComponent],
  imports: [CommonModule],
  exports: [NoLogoComponent],
})
export class NoLogoModule {}
