import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadImgComponent } from './lazy-load-img.component';

@NgModule({
  declarations: [LazyLoadImgComponent],
  imports: [CommonModule],
  exports: [LazyLoadImgComponent],
})
export class LazyLoadImgModule {}
