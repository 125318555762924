import { EventEmitter, Injectable } from '@angular/core';
import { rudderanalytics } from './analytics';
import { environment } from 'src/environments/environment';

import { ContentSection } from 'src/app/types/content-section.class';
import { ContentTypeOffer } from 'src/app/types/content-type-offer.class';
import { ContentTypeArticle } from 'src/app/types/content-type-article.class';
import { ContentTypeBrand } from 'src/app/types/content-type-brand.class';
import { ContentTypeImgBanner } from 'src/app/types/content-type-img-banner.class';
import { ContentTypeProductFeature } from 'src/app/types/content-type-product-feature.class';
import { ContentTypeCollection } from 'src/app/types/content-type-collection.class';
import { ContentTypeTag } from 'src/app/types/content-type-tag.class';
import { ContentTypeCategory } from '../types/content-type-category.class.ts';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public sessionChanged$: EventEmitter<boolean> = new EventEmitter();

  private _analyticsClient = rudderanalytics;
  private _user: string = null;
  private _sessionId: string = null;
  private _isInitialized: boolean = false;

  private _isEnabled: boolean = false;

  get user(): string {
    return this._user;
  }

  set user(user: string) {
    if (user) {
      if (this._user) {
        if (user !== this._user) {
          this.reset();
        }
      }
    }
  }

  get sessionId(): string {
    return this._sessionId;
  }

  set sessionId(sessionId: string) {
    this._sessionId = sessionId;
  }

  constructor() {
    this._analyticsClient.ready(() => {
    });
  }

  notifySessionChange() {
    this.sessionChanged$.emit(true);
  }

  initialize(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this._isInitialized) {
        this._isEnabled = environment.analytics.rudderstack.writeKey && environment.analytics.rudderstack.dataPlaneUrl;
        if (this._isEnabled) {
          this._analyticsClient.load(environment.analytics.rudderstack.writeKey, environment.analytics.rudderstack.dataPlaneUrl, { useAutoTracking: false });
        }
        this._isInitialized = true;
        this.setAnonymousId(this.sessionId);
        this.identify(this.user);
        return resolve(true);
      } else {
        return resolve(true);
      }
    });
  }

  identify(id?: string, traits?: any, options?: any, callback?: any) {
    this._user = id;
    // Changing User
    if (this._isEnabled) {
      this._analyticsClient.identify(id, traits, options, callback);
    }
  }

  alias(to: string, from?: string, options?: any, callback?: any) {
    if (this._isEnabled) {
      this._analyticsClient.alias(to, from, options, callback);
    }
  }

  page(category?: string, name?: string, properties?: any, options?: any, callback?: any) {
    if (this._isEnabled) {
      const trackData: any = {};
      for (const key in properties) {
        trackData[key] = this.sanitizeTrackingData(properties[key], key);
      }
      this._analyticsClient.page(category, name, trackData, options, callback);
    }
  }

  track(event: string, properties?: any, target?: Element, options?: any, callback?: any) {
    if (this._isEnabled) {
      const trackData: any = {};
      for (const key in properties) {
        trackData[key] = this.sanitizeTrackingData(properties[key], key);
      }
      trackData.element = {
        tagName: target.tagName,
        id: target.id,
        classList: target.classList.toString(),
      };
      this._analyticsClient.track(event, trackData, options, callback);
    }
  }

  group(group: string, traits?: any, options?: any, callback?: any) {
    if (this._isEnabled) {
      this._analyticsClient.group(group, traits, options, callback);
    }
  }

  reset() {
    if (this._isEnabled) {
      this._analyticsClient.reset();
    }
  }

  getAnonymousId() {
    return this._isEnabled ? this._analyticsClient.getAnonymousId() : this._sessionId;
  }

  setAnonymousId(id?: string) {
    this._sessionId = id;
    if (this._isEnabled) {
      this._analyticsClient.setAnonymousId(id);
    }
  }

  sanitizeTrackingData(data: any, dataType: string): any {
    if (dataType && data && Object.keys(data).length > 0) {
      switch (dataType) {
        case 'section':
          const section = <ContentSection>data;
          return {
            id: Number(section.id),
            title: section.data?.title || null,
            scrolling: section.data.config?.isHorizontalScroller ? 'horizontal' : 'none',
            cardSize: section.data.config?.cardSize || null,
            template: section.data.config?.template || null,
          };
        case 'offer':
          const offer = <ContentTypeOffer>data;
          return {
            id: offer.id,
            offerText: offer.data?.description,
            ctaURL: offer.data?.ctaURL,
            redirectUrl: offer.data?.redirectUrl,
            ctaText: offer.data?.ctaText,
            brandId: offer.data?.brand ? offer.data.brand.id : null,
            brandName: offer.data?.brand ? offer.data.brand.name : null,
            title: offer.data?.brand ? offer.data.brand.name : null,
          };
        case 'article':
          const article = <ContentTypeArticle>data;
          return {
            id: article.id,
            title: article.data?.title,
            ctaURL: article.data?.ctaURL,
            ctaText: article.data?.ctaText,
          };
        case 'brand':
          const brand = <ContentTypeBrand>data;
          return {
            id: brand.id,
            name: brand.data?.name,
            title: brand.data?.title,
            ctaURL: brand.data?.ctaURL,
            ctaText: brand.data?.title,
          };
        case 'imgBanner':
          const imgBanner = <ContentTypeImgBanner>data;
          return {
            id: imgBanner.id,
            ctaURL: imgBanner.data?.ctaURL,
            ctaText: imgBanner.data?.ctaText,
            image: imgBanner.data?.image,
          };
        case 'productFeature':
          const productFeature = <ContentTypeProductFeature>data;
          return {
            id: productFeature.id,
            title: productFeature.data?.title,
            ctaURL: productFeature.data?.ctaURL,
            ctaText: productFeature.data?.title,
          };
        case 'collection':
          const collection = <ContentTypeCollection>data;
          return {
            id: collection.id,
            title: collection.data?.title,
            ctaURL: collection.data?.ctaURL,
            ctaText: collection.data?.title,
          };
        case 'tag':
          const tag = <ContentTypeTag>data;
          return {
            id: tag.id,
            title: tag.data?.title || tag?.label,
          };
        case 'contentCategory':
          const category = <ContentTypeCategory>data;
          return {
            id: category.id,
            title: category.data?.label,
            ctaURL: category.data?.ctaURL,
            ctaText: category.data?.label,
          };
        default:
          return data;
      }
    } else {
      return undefined;
    }
  }
}
