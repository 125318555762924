import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lazy-load-img',
  templateUrl: './lazy-load-img.component.html',
  styleUrls: ['./lazy-load-img.component.scss'],
})
export class LazyLoadImgComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
