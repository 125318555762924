import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-page-layout',
  templateUrl: './detail-page-layout.component.html',
  styleUrls: ['./detail-page-layout.component.scss'],
})
export class DetailPageLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
