import { AfterViewInit, Directive, Input, OnDestroy, ElementRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Directive({
  selector: '[trackViewPortEvents]',
})
export class TrackViewPortEventsDirective implements AfterViewInit, OnDestroy {
  private readonly subscription: Subscription = new Subscription();
  private _previousEvent: 'rendered' | 'entering-viewport' | 'in-viewport' | 'exiting-viewport' | 'exited-viewport' = null;
  private _element: ElementRef;
  private observer: IntersectionObserver;

  constructor(el: ElementRef, private _analyticsService: AnalyticsService) {
    this._element = el;
  }

  @Input() trackData: any = {};

  public ngAfterViewInit(): void {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isCompletelyInViewPort = this._isElementCompletelyInViewport(entry.target);
          if (this._previousEvent === null) {
            // Rendered, but not in ViewPort
            this._previousEvent = 'rendered';
            this._analyticsService.track('rendered', this.trackData, entry.target);
          }
          if (entry.isIntersecting && !isCompletelyInViewPort && ['rendered', 'exited-viewport'].indexOf(this._previousEvent) > -1) {
            // Entering into ViewPort
            this._previousEvent = 'entering-viewport';
            this._analyticsService.track('entering-viewport', this.trackData, entry.target);
          } else if (
            entry.isIntersecting &&
            isCompletelyInViewPort &&
            ['rendered', 'entering-viewport', 'exiting-viewport'].indexOf(this._previousEvent) > -1
          ) {
            // Completely in ViewPort
            this._previousEvent = 'in-viewport';
            this._analyticsService.track('in-viewport', this.trackData, entry.target);
          } else if (entry.isIntersecting && !isCompletelyInViewPort && this._previousEvent === 'in-viewport') {
            // Exiting from ViewPort
            this._previousEvent = 'exiting-viewport';
            this._analyticsService.track('exiting-viewport', this.trackData, entry.target);
          } else if (!entry.isIntersecting && ['exiting-viewport', 'entering-viewport'].indexOf(this._previousEvent) > -1) {
            // Exited from ViewPort
            this._previousEvent = 'exited-viewport';
            this._analyticsService.track('exited-viewport', this.trackData, entry.target);
          }
        });
      },
      {
        threshold: [0, 1],
      }
    );

    this.observer.observe(this._element.nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
    this.subscription.unsubscribe();
  }

  /**
   * Check if a DOM element is completely visible in the current viewport.
   */
  private _isElementCompletelyInViewport(el: Element) {
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    return rect.left >= 0 && rect.top >= 0 && rect.left + rect.width <= windowWidth && rect.top + rect.height <= windowHeight;
  }
}
@Directive({
  selector: '[trackMouseEvents]',
})
export class TrackMouseEventsDirective {
  constructor(private _analyticsService: AnalyticsService) {}

  @Input() trackData: any = {};

  @HostListener('click', ['$event.target'])
  onClick(target: Element) {
    this._analyticsService.track('click', this.trackData, target);
    return true;
  }

  @HostListener('dblclick', ['$event.target'])
  onDblClick(target: Element) {
    this._analyticsService.track('dblclick', this.trackData, target);
    return true;
  }

  @HostListener('contextmenu', ['$event.target'])
  onContextMenu(target: Element) {
    this._analyticsService.track('contextmenu', this.trackData, target);
    return true;
  }

  @HostListener('mouseover', ['$event.target'])
  onMouseOver(target: Element) {
    this._analyticsService.track('mouseover', this.trackData, target);
    return true;
  }

  @HostListener('mouseout', ['$event.target'])
  onMouseOut(target: Element) {
    this._analyticsService.track('mouseout', this.trackData, target);
    return true;
  }
}
