import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { CoreModule } from 'src/app/core/core.module';

import { SearchByBrandComponent } from './search-by-brand.component';

@NgModule({
  declarations: [SearchByBrandComponent],
  imports: [CommonModule, FormsModule, CoreModule, TypeaheadModule.forRoot()],
  exports: [SearchByBrandComponent],
})
export class SearchByBrandModule {}
