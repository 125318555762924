import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationExtras } from '@angular/router';
import { DOCUMENT } from '@angular/common';

export interface RedirectionExtras extends NavigationExtras {
  target?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService  {
  constructor(@Inject(DOCUMENT) readonly document: Document, readonly router: Router) {}

  /** The Window object from Document defaultView */
  get window(): Window {
    return this.document.defaultView;
  }

  /** Redirects instantly to the external link without the mediation of the router */
  public redirect(url: string, target: string = '_blank'): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        resolve(!!(target === '_self' ? (this.document.location.href = url) : this.window.open(url, target)));
      } catch (e) {
        reject(e);
      }
    });
  }

  /** Returns true if the given url looks external */
  public external(url: string): boolean {
    // AP-8095: Ad Blocker Bypass for enhanced offer access - START
    return /^http(?:s)?:\/{2}\S+$/.test(url) || (url && url.startsWith('/offer/'));
    // AP-8095: Ad Blocker Bypass for enhanced offer access - END
  }

  /** Parses the given url for extenral redirection */
  private externalUrl(url: string, target?: string): string {
    return 'o?url=' + encodeURIComponent(url) + (!!target ? '&target=' + target : '');
  }

  /** Parses the given url for internal routing */
  private internalUrl(url: string): string {
    return url && url.startsWith('#') ? this.router.url + url : url && url.replace(/^\./, this.router.url);
  }

  /** Navigates to the given url, redirecting when necessary
   * @param url An absolute URL. The function does not apply any delta to the current URL.
   * When starting with 'http(s)://' triggers the external redirection.
   * @param extras (optional). An object containing properties that modify the navigation strategy.
   * The function ignores any properties that would change the provided URL.
   */
  public navigate(url: string, extras?: RedirectionExtras): Promise<boolean> {
    // Compose the url link for redirection
    const link = this.external(url) ? this.externalUrl(url, extras ? extras.target : undefined) : this.internalUrl(url);

    // Navigates with the router activating the redirection guard
    return this.router.navigateByUrl(link, extras);
  }

  canActivate(route: ActivatedRouteSnapshot) {
    // Gets the url query parameter, if any
    const url = decodeURIComponent(route.queryParamMap.get('url'));
    // If the url matches an external link, redirects stopping the route activation
    if (this.external(url)) {
      // Gets the optional target, when specified
      const target = route.queryParamMap.get('target');
      // Get the optional target if already added to the URL
      const urlTarget = url.startsWith('http') ? new URL(url).searchParams.get('target') : null;
      // Jumps to the external resource, giving precedence to target embedded in URL, and then to the specified target, if specified
      return this.redirect(url, urlTarget || target).then(() => false);
    }
    // Goes on activating the requested route, most likely to NotFound
    return true;
  }
}
