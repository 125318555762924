import { Directive, Input, HostBinding, HostListener } from '@angular/core';
import { NavigationService } from './navigation.service';

@Directive({
  selector: ':not(a):not(area)[navigateTo]',
})
export class NavigationDirective {
  constructor(readonly navigationService: NavigationService) {}

  @Input('navigateTo') url: string;

  @Input('disabled') set disable(value: boolean) {
    this.disabled = value != null && `${value}` !== 'false';
  }
  public disabled: boolean = false;

  @HostListener('click') onClick() {
    // Fallbakcs to default if no url is specified
    const url = this.url ? this.url.trim() : null;
    if (!url) {
      return true;
    }
    // Skips when disabled
    if (this.disabled) {
      return false;
    }
    // Navigates on the requested link redirecting when necessary
    this.navigationService.navigate(url);
    // Prevents default
    return false;
  }
}

@Directive({
  selector: 'a[navigateTo],area[navigateTo]',
})
export class NavigationWithHrefDirective extends NavigationDirective {
  constructor(navigationService: NavigationService) {
    super(navigationService);
  }

  // Binds the requested url to the href property
  @HostBinding('href') get href() {
    const url = this.url ? this.url.trim() : null;
    return url;
  }
}
