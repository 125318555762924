import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() isOpen: boolean = false;
  @Output() collapse: EventEmitter<void> = new EventEmitter<void>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  appVersion: string = environment.appVersion;
  isReady: boolean = false;
  hasOpened: boolean = false;
  menuItems: Array<any> = [];
  isLoading: boolean = false;
  retryError: Error;
  get isError(): boolean {
    return !!this.retryError;
  }

  constructor(private menuService: MenuService) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.isOpen) {
      this._init();
      this.hasOpened = true;
    } else {
      this.hasOpened = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => (this.isReady = true));
  }

  _init() {
    if (!this.menuItems) {
      this.menuItems = [];
    }
    if (this.menuItems.length === 0) {
      this.retryError = undefined;
      this.isLoading = true;
      this.menuItems = [];
      this.menuService.getMenu().subscribe(
        (resp) => {
          if (resp  && resp.result && resp.result.menu) {
            this.menuItems = resp.result.menu;
          }
          this.isLoading = false;
        },
        (err) => {
          console.error('Error getting menu items', err);
          this.retryError = err;
          this.isLoading = false;
        }
      );
    } else {
      this.retryError = undefined;
      this.isLoading = false;
    }
  }

  retry() {
    this._init();
  }

  collapseMenu() {
    this.collapse.emit();
  }

  goBack() {
    this.back.emit();
  }
}
