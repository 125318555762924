import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FooterService } from '../../services/footer.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DisclaimerNoticeComponent } from '../disclaimer-notice/disclaimer-notice.component';
import { WINDOW } from 'src/app/core/providers/window.provider';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  bsModalRef: BsModalRef;

  static modalRefs: BsModalRef[] = [];

  static isModalDisclaimerShown: boolean = false;

  footerContent: Array<any>;
  showFooterDisclaimer: boolean = false;

  bankName: string = environment.bankName;
  bankNameWithVariant: string = environment.bankNameWithVariant;
  productName: string = environment.productName;
  copyrightStartYear: string = '2019';
  copyrightEndYear: string = '2020';
  bankCopyrightText: string = environment.bankCopyrightText;

  constructor(
    private footerService: FooterService,
    private modalService: BsModalService,
    @Inject(WINDOW) private window: Window,
    private _analyticsService: AnalyticsService
  ) {
    this._analyticsService.sessionChanged$.subscribe(() => {
      this.window.localStorage.setItem('_isModalDisclaimerShown', 'FALSE');
      this._handleModalDisclaimer();
    });
  }

  ngOnInit() {
    this._init();
  }

  private _init() {
    this.showFooterDisclaimer = environment.disclaimer.position === 'footer' || environment.disclaimer.position === 'both';
    this._getFooterContent();
    this.copyrightEndYear = new Date().getFullYear().toString();
    this._handleModalDisclaimer();
  }

  private _getFooterContent() {
    this.footerService.getFooterContent().subscribe(
      (resp: any) => {
        if (resp) {
          this.footerContent = resp.data.result;
        }
      },
      (err) => {
        console.error('Error getting footer content', err);
      }
    );
  }

  private _handleModalDisclaimer() {
    FooterComponent.isModalDisclaimerShown = this.window.localStorage.getItem('_isModalDisclaimerShown') === 'TRUE' || false;
    if (!FooterComponent.isModalDisclaimerShown && (environment.disclaimer.position === 'modal' || environment.disclaimer.position === 'both')) {
      // Hide all open disclaimer modals
      for (const m of FooterComponent.modalRefs) {
        m.hide();
      }
      FooterComponent.modalRefs = [];

      const initialState = {
        isModal: true,
      };
      this.bsModalRef = this.modalService.show(
        DisclaimerNoticeComponent,
        Object.assign({}, { initialState }, { class: 'mt-5', ignoreBackdropClick: true, backDrop: 'static' })
      );
      this.bsModalRef.content.bsModalRef = this.bsModalRef;

      FooterComponent.modalRefs.push(this.bsModalRef);

      this.window.localStorage.setItem('_isModalDisclaimerShown', 'TRUE');
      FooterComponent.isModalDisclaimerShown = true;
    }
  }
}
