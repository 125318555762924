import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TabbedLayoutComponent } from './layouts/tabbed-page-layout/tabbed-layout.component';
import { DetailPageLayoutComponent } from './layouts/detail-page-layout/detail-page-layout.component';

import { TABBED_PAGE_LAYOUT_ROUTES } from './layouts/tabbed-page-layout/tabbed-page-layout.routes';
import { DETAIL_PAGE_LAYOUT_ROUTES } from './layouts/detail-page-layout/detail-page-layout.routes';
import { SpendAnalyzerComponent } from './pages/spend-analyzer/spend-analyzer.component';
import { GuardRoute } from './layouts/detail-page-layout/guard.route';
import { SwiperTesttComponent } from './swiper-testt/swiper-testt.component';

const routes: Routes = [
  {
    path: 'testt',
    component: SwiperTesttComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'spend-analyzer',
    canActivate:[GuardRoute],
    component: SpendAnalyzerComponent,
    children: DETAIL_PAGE_LAYOUT_ROUTES,
  },
  {
    path: '',
    component: DetailPageLayoutComponent,
    children: DETAIL_PAGE_LAYOUT_ROUTES,
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    component: TabbedLayoutComponent,
    children: TABBED_PAGE_LAYOUT_ROUTES,
    runGuardsAndResolvers: 'always',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
