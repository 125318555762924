import { Routes } from '@angular/router';
import { GuardRoute } from './guard.route';

export const DETAIL_PAGE_LAYOUT_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('../../pages/content-detail-page/content-detail-page.module').then((m) => m.ContentDetailPageModule),
  },
  // {
  //   path: 'spend-analyzer',
  //   canActivate: [GuardRoute],
  //   loadChildren: () => import('../../pages/spend-analyzer/spend-analyzer.module').then((m) => m.SpendAnalyzerModule),
  // },
];
