import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { PageHeaderModule } from './components/page-header/page-header.module';
import { EmptyStateModule } from './components/empty-state/empty-state.module';
import { FooterModule } from './components/footer/footer.module';
import { PreviewGoliveModule } from './components/preview-golive/preview-golive.module';
// import { SpendAnalyzerModule } from './components/spend-analyzer/spend-analyzer.module';

import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { TabbedLayoutComponent } from './layouts/tabbed-page-layout/tabbed-layout.component';
import { DetailPageLayoutComponent } from './layouts/detail-page-layout/detail-page-layout.component';

import { ApiInterceptor } from './interceptors/api.interceptor';

import { AppInitializer } from './initializers/app.initializer';
import { SwiperTesttComponent } from './swiper-testt/swiper-testt.component';
import { register } from 'swiper/element/bundle';
// import { SwiperDirective } from './core/directives/swiper.directive';

export function initializeApp(appInitializer: AppInitializer) {
  return (): Promise<any> => {
    return appInitializer.init();
  };
}

@NgModule({ declarations: [AppComponent, TabbedLayoutComponent, DetailPageLayoutComponent,SwiperTesttComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
        EmptyStateModule,
        PageHeaderModule,
        FooterModule,
        PreviewGoliveModule,
        // SpendAnalyzerModule,
        CoreModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitializer],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA] })
export class AppModule { 
    constructor() {
        register(); // Register Swiper components globally
    }    
}
