import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { constants } from 'src/config/constants';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  ENDPOINT_LOGIN = '/for-you/preview/go-live';
  fieldsToEncrypt = environment.FIELDS_TO_ENCRYPT;
  constructor(private http: HttpClient) {}

  login(params: { email: string; password: string }) {
    const json = {};
    Object.keys(params).forEach((key) => {
      if (this.fieldsToEncrypt.includes(key)) {
        json[key] = CryptoJS.AES.encrypt(params[key], environment.ENCRYPTION_KEY).toString();
      } else {
        json[key] = params[key];
      }
    });
    return this.http.put(constants.apiHost + this.ENDPOINT_LOGIN, json);
  }
}
