<div #searchWrapper class="search-wrapper is-focused">
  <input
    #searchInput
    class="search-input"
    id="search-input"
    type="text"
    [placeholder]="searchInputPlaceholderText"
    [(ngModel)]="q"
    [typeahead]="suggestions$"
    typeaheadOptionField="name"
    [typeaheadAsync]="true"
    [typeaheadOptionsLimit]="7"
    typeaheadWaitMs="300"
    [typeaheadIsFirstItemActive]="false"
    [typeaheadSelectFirstItem]="false"
    [isAnimated]="true"
    [optionsListTemplate]="customListTemplate"
    [typeaheadMinLength]="0"
    (typeaheadOnSelect)="typeaheadOnSelect($event)"
    (typeaheadNoResults)="typeaheadNoResults($event)"
    (typeaheadLoading)="changeTypeaheadLoading($event)"
    (click)="inputClicked()"
    trackMouseEvents
    trackViewPortEvents
    inViewport
    [trackData]="{
      property: 'search',
      template: isFocused ? 'search-bar' : 'search-button',
      searchTerm: q
    }"
  />
  <button
    class="clear"
    id="search-clear"
    (click)="clear()"
    trackMouseEvents
    trackViewPortEvents
    inViewport
    [trackData]="{
      property: 'search-clear',
      template: isFocused ? 'search-bar' : 'search-button',
      searchTerm: q
    }"
    *ngIf="isFocused && q && !typeaheadLoading"
    aria-labelledby="Search brand"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>
  </button>
  <img class="autosuggest-spinner" src="/assets/images/core/spinner.svg" alt="Autosuggest Spinner" *ngIf="typeaheadLoading && q" />
  <ul class="autosuggest-list-group no-results" id="autosuggest-list-group" *ngIf="!typeaheadLoading && q && noResult">
    <li class="autosuggest-list-group-item disabled" id="autosuggest-list-no-results">No results</li>
  </ul>
</div>

<ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
  <ul class="autosuggest-list-group" id="autosuggest-list-group">
    <li
      class="autosuggest-list-group-item"
      *ngFor="let match of matches"
      id="autosuggest-result-brand-{{ match.item.id }}"
      [class.active]="typeaheadTemplateMethods.isActive(match)"
      (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
      (mouseenter)="typeaheadTemplateMethods.selectActive(match)"
      trackMouseEvents
      trackViewPortEvents
      inViewport
      [trackData]="{
        property: 'search-result',
        template: isFocused ? 'search-bar' : 'search-button',
        searchTerm: q,
        brand: {
          id: match.item.id,
          data: match.item
        }
      }"
    >
      {{ match.value }}
      <a href="{{ match.item.ctaURL }}" id="autosuggest-result-brand-ctaurl-{{ match.item.id }}" class="d-none" [attr.aria-label]="match.value"> </a>
    </li>
  </ul>
</ng-template>
