<div class="app" [ngClass]="{ 'has-hover': hasHover, 'has-update': !isInitializing && isAppUpdateAvailable }">
  <ng-container *ngIf="!isInitializing">
    <div class="update-confirm-wrapper animate__animated animate__slideInDown" *ngIf="isAppUpdateAvailable">
      <div class="update-conform-container h-100 w-100">
        <div class="update-confirm d-flex h-100 align-items-center justify-content-center">
          <span class="update-text ml-1">New update available</span>
          <button
            type="button"
            class="btn-update-cta btn btn-ternary ml-2 mr-1"
            (click)="doAppUpdate()"
            inViewport
            trackViewPortEvents
            trackMouseEvents
            [trackData]="{
              property: 'update-cta',
              template: 'update-bar'
            }"
            aria-labelledby="Update"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </ng-container>
  <div class="loader-container min-height-100 full-min-height d-flex align-items-center justify-content-center px-2" *ngIf="isInitializing">
    <app-loader></app-loader>
  </div>
  <app-preview-golive></app-preview-golive>
  <div class="spend-analyzer-button"  style="display: none;">
    <!-- <spend-analyzer-button *ngIf="showSpendIndicatorIcon"></spend-analyzer-button> -->
  </div>
</div>
