import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-disclaimer-notice',
  templateUrl: './disclaimer-notice.component.html',
  styleUrls: ['./disclaimer-notice.component.scss'],
})
export class DisclaimerNoticeComponent implements OnInit {
  @Input() isModal: boolean = false;
  @Input() public bsModalRef?: BsModalRef;

  disclaimerText: string = environment.disclaimer.text;

  constructor() {}

  ngOnInit(): void {}
}
