import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';

import { WINDOW_PROVIDERS } from '../../core/providers/window.provider';
import { CoreModule } from '../../core/core.module';
import { FooterComponent } from './footer.component';
import { DisclaimerNoticeModule } from '../../components/disclaimer-notice/disclaimer-notice.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, RouterModule, CoreModule, ModalModule.forRoot(), DisclaimerNoticeModule],
  providers: [WINDOW_PROVIDERS],
  exports: [FooterComponent],
})
export class FooterModule {}
