/**
 * App AppInitializer handles config, session and analytics initialization, etc. before the app loads
 * @param httpClient
 * @returns
 */

import { Injectable } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';
import { ConfigService } from '../services/config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInitializer {
  constructor(private _configService: ConfigService, private _analyticsService: AnalyticsService) {}

  init() {
    return new Promise<void>(async (resolve) => {
      // Handle config, session and analytics initialization here
      try {
        await this._configService.getConfig();

        // Initialize Analytics Service
        this._analyticsService
          .initialize()
          .then(() => {
            
            // Hide loading and start the app
            (<HTMLElement>document.querySelector('.page-loading')).style.display = 'none';
            
            return resolve();
          })
          .catch((err) => {
            console.error('AppInitializer | init | Error', err);
            throw err;
          });
      } catch (err) {
        console.error('AppInitializer | init | getConfig | Error', err);
        setTimeout(() => {
          window.location.reload(); // Reload the page after a while
        }, 500);
      }
    });
  }
}
