import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CoreModule } from '../../core/core.module';
import { MenuComponent } from './menu.component';
import { ErrorStateModule } from '../../components/error-state/error-state.module';

@NgModule({
  declarations: [MenuComponent],
  imports: [CommonModule, ErrorStateModule, CoreModule, RouterModule],
  exports: [MenuComponent],
})
export class MenuModule {}
