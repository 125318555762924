<div appSwiper [swiperOptions]="swiperConfig" class="swiper-container">
  <div class="swiper-wrapper">
    <div class="swiper-slide" style="background: lightsalmon">Slide 1</div>
    <div class="swiper-slide" style="background: lightgreen">Slide 2</div>
    <div class="swiper-slide" style="background: lightblue">Slide 3</div>
  </div>

  <!-- Optional navigation buttons -->
  <div class="swiper-button-next">oneee</div>
  <div class="swiper-button-prev">twoo</div>

  <!-- Optional pagination -->
  <div class="swiper-pagination"></div>
</div>
