<div class="page-footer p-0 pb-md-4 px-md-4 pt-md-2">
  <div class="d-flex flex-wrap page-footer-items-container">
    <ng-container *ngFor="let content of footerContent">
      <div class="d-flex align-items-center list-group-item footer-item {{ content.orderClass }}" *ngIf="!content.ctaURL">
        <span class="col">
          <span class="footer-item-without-link" id="footer-item-{{ content.id }}" *ngIf="!content.ctaURL">
            {{ content?.label }}
          </span>
        </span>

        <span class="col-auto">
          <span *ngFor="let image of content?.images; let i = index">
            <picture>
              <img *ngIf="!image.ctaURL" class="footer-image" [src]="image.path" [alt]="content?.label" id="footer-item-{{ content.id }}-image-{{ i + 1 }}" />
            </picture>
            <a
              *ngIf="image.ctaURL"
              [navigateTo]="image.ctaURL"
              trackMouseEvents
              [trackData]="{
                property: 'footer-link',
                realEstateId: 'footer-item-' + content.id + '-image-' + i + 1,
                ctaURL: content.ctaURL,
                title: content?.label,
                image: image.path,
                template: 'page-footer-image-item'
              }"
              [attr.aria-label]="content?.label"
            >
              <picture>
                <img class="footer-image" [src]="image.path" [alt]="content?.label" id="footer-item-{{ content.id }}-image-{{ i + 1 }}" />
              </picture>
            </a>
          </span>
          <span *ngFor="let icon of content?.icons; let i = index">
            <i *ngIf="!icon.ctaURL" class="footer-icon icon {{ icon.name }} icon-fw" id="footer-item-{{ content.id }}-icon-{{ i + 1 }}"></i>
            <a
              *ngIf="icon.ctaURL"
              [navigateTo]="icon.ctaURL"
              trackMouseEvents
              [trackData]="{
                property: 'footer-link',
                realEstateId: 'footer-item-' + content.id + '-icon-' + i + 1,
                ctaURL: content.ctaURL,
                title: content?.label,
                icon: icon.name,
                template: 'page-footer-icon-item'
              }"
              [attr.aria-label]="content?.label"
            >
              <i class="footer-icon icon {{ icon.name }} icon-fw" id="footer-item-{{ content.id }}-icon-{{ i + 1 }}"> </i>
            </a>
          </span>
          <i *ngIf="content.lastIcon" class="{{ content.lastIcon }} d-md-none"></i>
        </span>
      </div>
      <a
        class="d-flex align-items-center list-group-item footer-item {{ content.orderClass }}"
        *ngIf="content.ctaURL"
        trackMouseEvents
        [trackData]="{
          property: 'footer-link',
          realEstateId: 'footer-item-' + content.id,
          ctaURL: content.ctaURL,
          title: content?.label,
          template: 'page-footer-default-item'
        }"
        [navigateTo]="content.ctaURL"
        [attr.aria-label]="content?.label"
      >
        <span class="col">
          <span class="footer-item-with-link" id="footer-item-{{ content.id }}">
            {{ content?.label }}
          </span>
        </span>

        <span class="col-auto">
          <span *ngFor="let image of content?.images; let i = index">
            <picture>
              <img *ngIf="!image.ctaURL" class="footer-image" [src]="image.path" [alt]="content?.label" id="footer-item-{{ content.id }}-image-{{ i + 1 }}" />
            </picture>
            <a
              *ngIf="image.ctaURL"
              [navigateTo]="image.ctaURL"
              trackMouseEvents
              [trackData]="{
                property: 'footer-link',
                realEstateId: 'footer-item-' + content.id + '-image-' + i + 1,
                ctaURL: content.ctaURL,
                title: content?.label,
                image: image.path,
                template: 'page-footer-image-item'
              }"
              [attr.aria-label]="content?.label"
            >
              <picture>
                <img class="footer-image" [src]="image.path" [alt]="content?.label" id="footer-item-{{ content.id }}-image-{{ i + 1 }}" />
              </picture>
            </a>
          </span>
          <span *ngFor="let icon of content?.icons; let i = index">
            <i *ngIf="!icon.ctaURL" class="footer-icon icon {{ icon.name }} icon-fw" id="footer-item-{{ content.id }}-icon-{{ i + 1 }}"></i>
            <a
              *ngIf="icon.ctaURL"
              [navigateTo]="icon.ctaURL"
              trackMouseEvents
              [trackData]="{
                property: 'footer-link',
                realEstateId: 'footer-item-' + content.id + '-icon-' + i + 1,
                ctaURL: content.ctaURL,
                title: content?.label,
                icon: icon.name,
                template: 'page-footer-icon-item'
              }"
              [attr.aria-label]="content?.label"
            >
              <i class="footer-icon icon {{ icon.name }} icon-fw" id="footer-item-{{ content.id }}-icon-{{ i + 1 }}"></i>
            </a>
          </span>
          <i *ngIf="content.lastIcon" class="{{ content.lastIcon }} d-md-none footer-more-icon"></i>
        </span>
      </a>
    </ng-container>
  </div>

  <div class="footer-disclaimer-container">
    <app-disclaimer-notice *ngIf="showFooterDisclaimer"></app-disclaimer-notice>
  </div>
  <div class="d-flex flex-column flex-md-row copyright-container align-items-center px-2 my-3">
    <div class="text-center text-md-left order-2 order-md-0" id="footer-copyright-text">
      Copyright &copy; {{ copyrightStartYear }}-{{ copyrightEndYear }} {{ bankCopyrightText }}
    </div>
    <div class="mx-auto mr-md-0 text-center order-1 order-md-0">
      <img
        src="/assets/images/core/powered-by-g101.png"
        alt="Powered by Goals101"
        height="17"
        class="img-fluid d-block"
        style="max-height: 17px"
        id="footer-copyright-image"
      />
    </div>
  </div>
</div>
