<header class="header d-flex align-items-center">
  <ng-container *ngIf="menuFeatureFlag">
    <button
      type="button"
      class="btn btn-menu text-decoration-none"
      id="menu-open-button"
      *ngIf="showMenu && !toggleMenuBar"
      (click)="toggleMenuBar = !toggleMenuBar"
      trackMouseEvents
      [trackData]="{
        property: 'menu-button',
        realEstateId: 'menu-open-button',
        template: 'page-header'
      }"
      aria-label="Menu open"
    >
      <i class="icon icon-menu"></i>
    </button>
    <button
      type="button"
      class="btn btn-menu text-decoration-none animate__animated animate__fadeIn animate__faster"
      id="menu-close-button"
      *ngIf="showMenu && toggleMenuBar"
      (click)="toggleMenuBar = !toggleMenuBar"
      trackMouseEvents
      [trackData]="{
        property: 'menu-button-close',
        realEstateId: 'menu-close-button',
        template: 'page-header'
      }"
      aria-label="Menu close"
    >
      <i class="icon icon-angle-left"></i>
    </button>
    <button
      type="button"
      class="btn btn-back text-decoration-none"
      id="menu-close-button"
      *ngIf="showBackButton"
      (click)="back()"
      trackMouseEvents
      [trackData]="{
        property: 'back-button',
        realEstateId: 'menu-close-button',
        template: 'page-header'
      }"
      aria-label="Back button"
    >
      <i class="icon icon-arrow-left"></i>
    </button>
    <app-menu [isOpen]="toggleMenuBar" (collapse)="collapseMenu()" (back)="back()"></app-menu>
  </ng-container>
  <app-search-by-brand class="ml-1" *ngIf="searchFeatureFlag"></app-search-by-brand>
  <a
    [title]="bankName"
    class="logo-container d-inline-block ml-auto mr-2"
    id="{{ bankName | spaceHyphen }}"
    routerLink="/"
    trackMouseEvents
    [trackData]="{
      property: 'logo',
      realEstateId: bankName | spaceHyphen,
      title: bankName,
      template: 'bank-logo'
    }"
    [attr.aria-label]="bankName"
  >
    <picture>
      <img src="/assets/images/logos/logo.png" class="logo" [alt]="bankName" />
    </picture>
  </a>
</header>
