import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'spaceHyphen',
})
export class spaceHyphenPipe implements PipeTransform {
  transform(menuLabel: string): any {

      return menuLabel.replace(/\s+/g, '-').toLowerCase();
    
  }
}
